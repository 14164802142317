import React, { useState, useEffect } from 'react'
import { Flex, Box, SpinnerIcon } from '@fatlama/llama-library'
import Header from './Header'
import { QueryUsersResponse } from './types'
import Results from './Results'
import GetApi from '../../utils/api'
import Pagination from '../Common/Pagination'
import debounce from 'debounce-promise'

const initialResults: QueryUsersResponse & { noInitialLoad?: boolean } = {
  pageNumber: 1,
  pageCount: 1,
  users: [],
  noInitialLoad: true
}
const fetchUsers = async ({
  searchTerm,
  api,
  pageNumber,
  setResults,
  setIsLoading,
  abortController
}: any) => {
  const filters = {
    searchTerm: (searchTerm || '').trim()
  }
  setIsLoading(true)
  const res = await api.getUsers(
    {
      pageNumber,
      filters
    },
    { signal: abortController.signal }
  )

  // IMPORTANT: we still need to filter the results here,
  // in case abortion happens during the delay.
  // In real apps, abortion could happen when you are parsing the json,
  // with code like "fetch().then(res => res.json())"
  // but also any other async then() you execute after the fetch
  if (abortController.signal.aborted) {
    return
  }

  const result: QueryUsersResponse = res.payload
  setResults(result)
  setIsLoading(false)
}

const debouncedFetchUsers = debounce(fetchUsers, 400)

const Customers = () => {
  const [results, setResults] = useState(initialResults)
  const [searchTerm, setSearchTerm] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const api = GetApi()
  const [headerHeight, setheaderHeight] = useState(90)
  const getAndSetHeight = () => {
    const header = document.getElementById('header')
    if (header) {
      setheaderHeight(header.offsetHeight)
    }
  }

  useEffect(() => {
    getAndSetHeight()
    window.addEventListener('resize', getAndSetHeight)
    return () => {
      window.removeEventListener('resize', getAndSetHeight)
    }
  }, [])

  const setPageNumber = (pageNumber: number) => {
    setResults({
      ...results,
      pageNumber
    })
  }

  useEffect(() => {
    const abortController = new AbortController()

    debouncedFetchUsers({
      searchTerm,
      api,
      pageNumber: results.pageNumber,
      setResults,
      abortController,
      setIsLoading
    })
    // Trigger the abortion in useEffect's cleanup function
    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, results.pageNumber])

  return (
    <Flex flexDirection='column'>
      <Header
        setSearchTerm={setSearchTerm}
        id='header'
        isLoading={isLoading}
        searchTerm={searchTerm}
      />
      <Box px={3} pt={headerHeight + 16} position='relative'>
        {results.noInitialLoad ? (
          <Flex alignItems='center' justifyContent='center' minHeight='400px'>
            <SpinnerIcon />
          </Flex>
        ) : (
          <Results results={results.users} />
        )}
        <Pagination
          currentPage={results.pageNumber}
          totalPages={results.pageCount}
          goToPage={setPageNumber}
        />
      </Box>
    </Flex>
  )
}

export default Customers
