export default (state: any) => ({
  ...state,
  creditUsed: Math.round(state.creditUsed),
  dailyPrice: Math.round(state.dailyPrice),
  dailyPriceAfterLenderCode: Math.round(state.dailyPriceAfterLenderCode),
  borrowerTotalCost: Math.round(state.borrowerTotalCost),
  borrowerFatLamaFee: Math.round(state.borrowerFatLamaFee),
  fatLamaTotalFee: Math.round(state.fatLamaTotalFee),
  fatLamaWalletTopUp: Math.round(state.fatLamaWalletTopUp),
  lenderEarnings: Math.round(state.lenderEarnings),
  lenderFatLamaFee: Math.round(state.lenderFatLamaFee),
  multiDaySaving: Math.round(state.multiDaySaving),
  percentSaving: Math.round(state.percentSaving),
  rentalCost: Math.round(state.rentalCost),
  rentalCostAfterLenderCode: Math.round(state.rentalCostAfterLenderCode),
  promocodeDiscount: Math.round(state.promocodeDiscount),
  promocodeDiscountAfterLenderCode: Math.round(state.promocodeDiscountAfterLenderCode),
  vat: Math.round(state.vat)
})
