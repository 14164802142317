import React, { useState } from "react";
import { Formik } from "formik";
import {
  Flex,
  Box,
  Button,
  Text,
  Modal,
  H3,
  Error,
} from "@fatlama/llama-library";
import GetApi from "../../utils/api";
import { useHistory } from "react-router-dom";
import { RentalAbilityPauseReason, RentalAbilityPause } from "../../interfaces";
import Dropdown from "../Common/Dropdown";
import { remove } from "lodash";

interface Props {
  userId: number;
  current: RentalAbilityPause | null;
}

interface FormValues {
  reason?: RentalAbilityPauseReason | null;
}

const validate = (values: FormValues) => {
  const errors = {} as any;
  const { reason } = values;

  if (!reason) {
    errors.reason = "Required";
  }

  return errors;
};

const RentalAbilityPauseComponent = ({ userId, current }: Props) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [error, setError] = useState<string>();
  const [isRemoving, setIsRemoving] = useState(false);
  const history = useHistory();

  const removePause = async () => {
    setIsRemoving(true);
    const res = await api.removeRentalAbilityPause({ userId });

    if (res.success) {
      history.push(`${history.location.pathname}?refresh=true`);
    } else {
      setError("Unable to unblock user");
    }
    setIsRemoving(false);
  };

  const api = GetApi();
  const onSubmit = async (
    values: FormValues,
    { setSubmitting, isSubmitting }: any
  ) => {
    if (isSubmitting) {
      return;
    }
    setSubmitting(true);
    const { reason } = values;
    if (!reason) {
      return;
    }
    try {
      setError(undefined);

      const res = await api.addRentalAbilityPause({
        pausedBecause: reason,
        userId,
      });

      if (res.success) {
        history.push(`${history.location.pathname}?refresh=true`);
        setShowCancelModal(false);
      } else {
        setError("Unable to restrict user");
      }
    } catch (error) {
      console.log(error);
      setError("Unable to restrict user");
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = {
    reason: current?.pausedBecause,
  };

  return (
    <>
      {/* <Modal isOpen={showCancelModal} onClose={() => setShowCancelModal(false)}> */}
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          errors,
          touched,
          isSubmitting,
          isValid,
          setFieldTouched,
        }) => {
          return (
            <Flex flexDirection="column">
              {/* <H3 mb={2}>Restrict user account</H3> */}
              <Box>
                <Box mb={3} maxWidth="300px">
                  <Dropdown
                    label="Type of restriction (Required)"
                    error={
                      errors.reason && touched.reason ? errors.reason : null
                    }
                    options={[
                      RentalAbilityPauseReason.CAPPED,
                      RentalAbilityPauseReason.NOT_YET_TRUSTED,
                      RentalAbilityPauseReason.UNTRUSTED,
                      RentalAbilityPauseReason.NON_RETURN,
                    ]}
                    handleChange={(event: any) => {
                      setFieldValue("reason", event.target.value);
                      setFieldTouched("reason", true, false);
                    }}
                    value={values.reason ? values.reason : undefined}
                  />
                </Box>

                {error && <Error>{error}</Error>}
              </Box>
              <Box flexDirection="row">
                <Button
                  mr={2}
                  variant="primary"
                  isLoading={isSubmitting}
                  disabled={!isValid || isSubmitting}
                  onClick={handleSubmit}
                  label={
                    current?.pausedBecause &&
                    values.reason !== current?.pausedBecause
                      ? "Change restriction"
                      : " Restrict"
                  }
                />

                {current?.pausedBecause ? (
                  <Button
                    variant="alert"
                    isLoading={isRemoving}
                    disabled={isRemoving}
                    onClick={removePause}
                    alert
                    label="Un Restrict"
                  />
                ) : null}
              </Box>
            </Flex>
          );
        }}
      </Formik>
      {/* </Modal> */}
    </>
  );
};

export default RentalAbilityPauseComponent;
