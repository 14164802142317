import { useEffect, useState } from 'react'

let lastScroll = 0
const scrollDetla = 300
let didScroll: any

const ScrollHideHeader = (target?: string, adjustment?: number) => {
  const [isPastScroll, setIsPastScroll] = useState(false)

  const hideHeaderFunc = (event: any, target?: any) => {
    const scrollTarget = (target ? event.target : document.body || document.documentElement) as any
    // This is for is at bottom:
    if (
      scrollTarget.scrollHeight - document.documentElement.scrollTop <=
        scrollTarget.clientHeight + adjustment &&
      !isPastScroll
    ) {
      setIsPastScroll(true)
    } else {
      setIsPastScroll(false)
    }

    // And this is for hidign header
    const scrollPosition = scrollTarget.scrollTop || document.documentElement.scrollTop
    const header = document.getElementById('header') as any
    // Handle hiding the header
    if (!header) {
      return
    }
    const change = Math.abs(lastScroll - scrollPosition)
    if (change <= scrollDetla) {
      return
    }
    // Scroll down

    if (scrollPosition > lastScroll && scrollPosition > 260) {
      header.classList.add('header-up')
      // Scroll up
    } else {
      header.classList.remove('header-up')
    }
    lastScroll = scrollPosition
  }

  const scrollTarget = (target ? (document.getElementById(target) as any) || window : window) as any

  const handleScroll = (event: any) => {
    didScroll = true

    setInterval(() => {
      if (didScroll) {
        hideHeaderFunc(event, target)
        didScroll = false
      }
    }, 250)
  }

  useEffect(() => {
    scrollTarget.addEventListener('scroll', handleScroll)
    return () => scrollTarget.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTarget])

  if (typeof window === 'undefined') {
    return
  }
  const header = document.getElementById('header') as any
  // Handle hiding the header

  if (!header) {
    return
  }

  if (!scrollTarget) {
    return
  }

  return isPastScroll
}

export default ScrollHideHeader
