import getPromocodeFunctions from './getPromocodeFunctions'

const applyNormalPromocode = (code: any, payload: any) => {
  const { lenderEarnings, borrowerTotalCost } = payload
  const { getNewPrice } = getPromocodeFunctions(code) as any
  // Calculate new cost based on code used

  const newBorrowerTotalCost = getNewPrice(borrowerTotalCost)
  // FL tops up lender wallet with whatever is left to pay
  const fatLamaWalletTopUp =
    newBorrowerTotalCost >= lenderEarnings ? 0 : lenderEarnings - newBorrowerTotalCost

  return {
    ...payload,
    ...{
      borrowerTotalCost: newBorrowerTotalCost,
      fatLamaWalletTopUp,
      promocodeDiscount: borrowerTotalCost - newBorrowerTotalCost,
      needsTransfer: Math.round(fatLamaWalletTopUp) > 0
    }
  }
}

const applyLenderPromocode = (code: any, payload: any) => {
  const {
    lenderEarnings,
    vat,
    rentalCost,
    lenderFatLamaFee,
    borrowerFatLamaFee,
    borrowerTotalCost,
    dailyPriceNotRounded,
    fatLamaTotalFee
  } = payload
  const { getNewPrice, transformOtherValues } = getPromocodeFunctions(code) as any
  const newRentalCost = transformOtherValues(rentalCost)
  return {
    ...payload,
    ...{
      dailyPriceAfterLenderCode: transformOtherValues(dailyPriceNotRounded),
      borrowerTotalCost: getNewPrice(borrowerTotalCost),
      lenderEarnings: transformOtherValues(lenderEarnings),
      rentalCostAfterLenderCode: transformOtherValues(rentalCost),
      vat: transformOtherValues(vat),
      fatLamaWalletTopUp: 0,
      lenderFatLamaFee: transformOtherValues(lenderFatLamaFee),
      borrowerFatLamaFee: transformOtherValues(borrowerFatLamaFee),
      fatLamaTotalFee: transformOtherValues(fatLamaTotalFee),
      promocodeDiscount: 0,
      promocodeDiscountAfterLenderCode: rentalCost - newRentalCost,
      needsTransfer: false
    }
  }
}

const applyPromocode = (payload: any) => {
  const { promocode } = payload
  if (promocode && promocode.includes('LENDER')) {
    return applyLenderPromocode(promocode, payload)
  } else if (promocode && (promocode.includes('PERCENT') || promocode.includes('FIXED'))) {
    return applyNormalPromocode(promocode, payload)
  } else {
    return {
      ...payload,
      ...{
        fatLamaWalletTopUp: 0,
        needsTransfer: false,
        promocodeDiscount: 0,
        promocode: undefined
      }
    }
  }
}

export default applyPromocode
