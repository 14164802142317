import { useEffect, useState } from "react";
import { uniqBy, prop } from "ramda";

import GetApi from "../../../utils/api";

const useUserVerifications = (
  borrowerId?: number
): {
  userSearches: any[];
  canShowMore: boolean;
  showMore(): void;
  loadingUserSearches: boolean;
} => {
  const [userSearches, setUserSearches] = useState<any[]>([]);
  const [loadingUserSearches, setLoadingUserSearches] = useState(false);
  const [visisbleSearches, setVisibleSearches] = useState<any[]>([]);
  const api = GetApi();

  const getSearches = async () => {
    if (!borrowerId) {
      return;
    }
    setLoadingUserSearches(true);
    const response = await api.getUserSearches(borrowerId);

    console.log(response, "-===response");
    if (response.success) {
      const allSearches = uniqBy(
        prop("searchTerm"),
        response.payload || []
      ).slice(0, 200); // limit to 200
      setUserSearches(allSearches);
      setVisibleSearches(allSearches.slice(0, 15));
    }
    setLoadingUserSearches(false);
  };

  const showMore = () =>
    setVisibleSearches(userSearches.slice(0, visisbleSearches.length + 15));

  useEffect(() => {
    getSearches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [borrowerId]);

  return {
    canShowMore: userSearches.length >= 15,
    userSearches: visisbleSearches,
    // userSearches,
    showMore,
    loadingUserSearches,
  };
};

export default useUserVerifications;
