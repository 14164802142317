import React from "react";
import styled from "styled-components";
import {
  Span,
  Text,
  Flex,
  getColor,
  getSpace,
  resSettings,
} from "@fatlama/llama-library";

import range from "lodash/range";
import concat from "lodash/concat";

import Icon from "./Icon";
// Creates the page navigation items
// given which page the user is on.
// Returns an array with each navigation item
// which will populate the navigation item circles.
const getNavItemsToShow = ({ currentPage, totalPages }: any) => {
  const firstNavItems = [1, "..."];
  const lastNavItems = ["...", totalPages];

  if (totalPages <= 5) {
    return range(1, totalPages + 1);
  }
  // If 3 away or less from start
  // should return like this
  //  [1, 2, 3, 4, '...', totalPages]
  const is3AwayFromStart = currentPage < 4;
  if (is3AwayFromStart) {
    return [1, 2, 3, 4, ...lastNavItems];
  }
  // If 3 away or less from end
  // should return like this
  //  [1, '...', totalPages-3, totalPages-2, totalPages-1, totalPages]
  const is3AwayFromEnd = totalPages - currentPage < 3;
  if (is3AwayFromEnd) {
    return concat(firstNavItems, range(totalPages - 3, totalPages + 1));
  }
  // If not special case
  // should return something like this
  //  [1, '...', 3, 4, 5, '...', 9]
  const pagesOneAway = [currentPage - 1, currentPage, currentPage + 1];
  return concat(firstNavItems, concat(pagesOneAway, lastNavItems));
};

const PageNavigationItem = styled(Flex)`
  height: ${getSpace(5)};
  width: ${getSpace(5)};
  line-height: ${getSpace(5)};
  text-align: center;
  margin-right: ${getSpace(2)};
  justify-content: center;
  align-items: center;
`;

interface PageNumberButtonProps {
  backgroundColor: string;
}

const PageNumberButton = styled(PageNavigationItem)`
  border-radius: ${getSpace(3)};
  cursor: pointer;
  background-color: ${(props: PageNumberButtonProps) =>
    getColor(props.backgroundColor)};
`;

interface PageNavigationArrowProps {
  disabled: boolean;
}

const PageNavigationArrow = styled(PageNumberButton)`
  background-color: ${getColor("gsWhite")};
  border: 1px solid ${getColor("midNight")};
  color: ${getColor("midNight")};
  opacity: ${(props: PageNavigationArrowProps) => (props.disabled ? 0.3 : 1)};
  pointer-events: ${(props: PageNavigationArrowProps) =>
    props.disabled ? "none" : "auto"};
`;

const RotatedLeftIcon = styled(Icon)`
  position: relative;
  right: 1px;
  transform: rotate(90deg);
`;

const RotatedRightIcon = styled(Icon)`
  position: relative;
  left: 1px;
  transform: rotate(270deg);
`;

// These icons don't fit into our styled system because of their triangular nature,
// which makes them look weird sized compared to other things in our styled system. They are a special case.
const ChevronLeft = () => (
  <RotatedLeftIcon icon="chevron" width={10} height={10} color="midNight" />
);
const ChevronRight = () => (
  <RotatedRightIcon icon="chevron" width={10} height={10} color="midNight" />
);

const NavigationButton = ({ isCurrentPage, navItem, goToPage, index }: any) => {
  // When nav item is not a number it
  // is an ellipses to signify many
  // pages in between
  if (typeof navItem !== "number") {
    return (
      <PageNavigationItem key={index}>
        <Span color="midNight">...</Span>
      </PageNavigationItem>
    );
  }
  return (
    <PageNumberButton
      key={index}
      backgroundColor={isCurrentPage ? "midNight" : "none"}
      color={isCurrentPage ? "white" : "midNight"}
      onClick={() => goToPage(navItem)}
    >
      <Span mb={0} color={isCurrentPage ? "white" : "midNight"}>
        {navItem}
      </Span>
    </PageNumberButton>
  );
};

const NavigationArrows = ({
  currentPage,
  totalPages,
  goForward,
  goBack,
}: any) => {
  return (
    <Flex width="auto" mb={3}>
      <PageNavigationArrow mr={1} onClick={goBack} disabled={currentPage === 1}>
        <ChevronLeft />
      </PageNavigationArrow>
      <PageNavigationArrow
        onClick={goForward}
        disabled={currentPage === totalPages}
      >
        <ChevronRight />
      </PageNavigationArrow>
    </Flex>
  );
};

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  total?: number;
  disabled?: boolean;
  goToPage(page: number): void;
}

class Pagination extends React.PureComponent<PaginationProps> {
  goBack = () =>
    !this.props.disabled && this.props.goToPage(this.props.currentPage - 1);

  goForward = () =>
    !this.props.disabled && this.props.goToPage(this.props.currentPage + 1);

  render() {
    const { currentPage, totalPages, goToPage, total } = this.props;
    // if (totalPages < 2) {
    //   return null;
    // }
    const navigationItems = getNavItemsToShow({
      currentPage,
      totalPages,
    });

    return (
      <Flex flexDirection="column">
        <Flex
          pt={2}
          justifyContent="space-between"
          alignItems="center"
          flexDirection={resSettings("column", "row", 2)}
        >
          <Flex width="auto" mb={3}>
            {navigationItems.map((navItem, index) => (
              <NavigationButton
                key={`nav-${index}`}
                navItem={navItem}
                isCurrentPage={navItem === currentPage}
                goToPage={(page: any) => {
                  if (this.props.disabled) {
                    return;
                  }
                  goToPage(page);
                }}
                index={index}
              />
            ))}
          </Flex>
          <NavigationArrows
            currentPage={currentPage}
            totalPages={totalPages}
            goForward={this.goForward}
            goBack={this.goBack}
          />
        </Flex>
        <Text>Total: {total}</Text>
      </Flex>
    );
  }
}

export default Pagination;
