import {
  Tab,
  QueryFilters,
  VerificationStatusFilter,
  RentalDateFilter,
} from "./types";
import dayjs from "dayjs";

export const getTimeSince = (movedToSecurityReviewAt?: Date | null) => {
  if (!movedToSecurityReviewAt) {
    return null;
  }
  const minsInSecReview = dayjs(dayjs()).diff(
    movedToSecurityReviewAt,
    "minute"
  );
  const hoursinSecReview = dayjs(dayjs()).diff(movedToSecurityReviewAt, "hour");
  const daysInSecReview = dayjs(dayjs()).diff(movedToSecurityReviewAt, "day");
  const shouldShowDays = hoursinSecReview >= 48;
  const shouldShowHours = minsInSecReview >= 60 && daysInSecReview < 2;
  const shouldShowMins = minsInSecReview < 60;
  if (shouldShowMins) {
    return minsInSecReview + " mins";
  }
  if (shouldShowHours) {
    return hoursinSecReview + " hours";
  }
  if (shouldShowDays) {
    return daysInSecReview + " days";
  }

  return null;
};

export const getFiltersFromTabsAndFilter = ({
  activeTab,
  activeFilter,
}: {
  activeTab: Tab;
  activeFilter?: VerificationStatusFilter;
}): QueryFilters => {
  if (activeTab === Tab.ALL) {
    // no filters
    return {};
  }

  if (activeTab === Tab.PAST) {
    return {
      dateFilter: RentalDateFilter.PAST,
    };
  }

  return {
    dateFilter: RentalDateFilter.ACTIVE,
    verificationStatus: activeFilter,
  };
};
