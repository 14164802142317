import React, { useState } from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import { Box, H3, Text, Hr, Span, Flex, Button } from "@fatlama/llama-library";
import { Link } from "react-router-dom";
import { Page } from "../../interfaces";
import RentalStatus from "../Common/RentalStatus";
import { OrderDataType } from "../../interfaces";
import { descend, prop, sort } from "ramda";
import Toggle from "../Common/Toggle";
import currencyMap from "../../constants/currencyMap";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const Rental = ({ tx }: { tx: OrderDataType }) => {
  return (
    <Flex>
      <Flex width="auto" alignItems="center" flexDirection="column" pr={4}>
        <RentalStatus orderStatus={tx.orderStatus} />
        <Text mb={0} pt={1} small color="copyTwo">
          {dayjs(tx.createdAt).utc().format("ddd D MMM")}
        </Text>
      </Flex>

      <Flex>
        <Box>
          <Text bold>
            {tx.items.length > 1
              ? `${tx.items.length} items`
              : tx.items[0].itemName}
            {" - "}
            <Span>
              {currencyMap[tx.currency]}
              {(tx.orderFullTotal / 100).toFixed(2)}
            </Span>
          </Text>
          <Flex justifyContent="space-between">
            <Text color="copyTwo">
              {tx.fromDate && dayjs(tx.fromDate).utc().format("D MMM")} -{" "}
              {tx.toDate && dayjs(tx.toDate).utc().format("D MMM YYYY")}{" "}
            </Text>

            <Text color="copyTwo">
              Total value: {currencyMap[tx.currency]}
              {(tx.totalEstimatedValue / 100).toFixed(2)}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

const ClickAbleLink = styled(Link)`
  cursor: pointer;
`;
const sortByDate = descend(prop("createdAt"));

const Rentals = ({
  transactions,
  email,
}: {
  email: string;
  transactions: {
    totalLending: number;
    totalBorrowing: number;
    lendingTransactions: Record<number, OrderDataType>;
    borrowingTransactions: Record<number, OrderDataType>;
  };
}) => {
  const {
    borrowingTransactions,
    totalBorrowing,
    totalLending,
    lendingTransactions,
  } = transactions;
  const sortedBorrowingTxs = sort(
    sortByDate,
    Object.values(borrowingTransactions)
  );
  const sortedLendingTxs = sort(sortByDate, Object.values(lendingTransactions));

  const [showBorrowing, setShowBorrowing] = useState(
    sortedBorrowingTxs.length > sortedLendingTxs.length
  );

  const [borrowingTxsToShow, setBorrowingTxsToShow] = useState(
    sortedBorrowingTxs.slice(0, 5)
  );
  const [lendingTxsToShow, setLendingTxsToShow] = useState(
    sortedLendingTxs.slice(0, 5)
  );

  const transactionsToShow = showBorrowing
    ? borrowingTxsToShow
    : lendingTxsToShow;
  const hasMoreToInPageRender = showBorrowing
    ? borrowingTxsToShow.length !== sortedBorrowingTxs.length
    : lendingTxsToShow.length !== sortedLendingTxs.length;

  const hasMoreToLoadInSearch = showBorrowing
    ? totalBorrowing > sortedBorrowingTxs.length
    : totalLending > sortedLendingTxs.length;

  return (
    <Box mb={5}>
      {sortedBorrowingTxs.length > 0 && sortedLendingTxs.length > 0 && (
        <Flex mb={3} justifyContent="center">
          <Toggle
            leftText="Borrowing"
            rightText="Lending"
            onLeftClick={() => setShowBorrowing(true)}
            onRightClick={() => setShowBorrowing(false)}
            leftActive={showBorrowing}
          />
        </Flex>
      )}

      <H3>
        Rentals:{" "}
        {showBorrowing
          ? `Borrowing (${totalBorrowing})`
          : `Lending  (${totalLending})`}
      </H3>

      <Hr mb={2} />

      {transactionsToShow.map((tx: OrderDataType) => {
        return (
          <>
            <ClickAbleLink to={`${Page.rentals}/${tx.orderId}`}>
              <Rental tx={tx} />
            </ClickAbleLink>
            <Hr mb={2} />
          </>
        );
      })}
      <Flex justifyContent="flex-end" pt={3}>
        {hasMoreToInPageRender && (
          <Button
            mr={3}
            variant="secondary"
            large
            label="See more"
            onClick={() => {
              return showBorrowing
                ? setBorrowingTxsToShow(sortedBorrowingTxs)
                : setLendingTxsToShow(sortedLendingTxs);
            }}
          />
        )}

        {transactionsToShow && transactionsToShow.length > 0 && (
          <ClickAbleLink
            to={`${Page.rentals}?activeFilter=ALL&activeTab=ALL&searchTerm=${email}&sortBy=TX_FROM_DATE&sortOrder=DESC`}
          >
            <Button
              variant="secondary"
              large
              label={
                hasMoreToLoadInSearch ? "See all rentals" : "See in rentals"
              }
            />
          </ClickAbleLink>
        )}
      </Flex>
    </Box>
  );
};

export default Rentals;
