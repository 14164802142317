import dayjs from 'dayjs'
import React from 'react'
import styled from 'styled-components'
import Icon from '../Common/Icon'
import { Flex, getColor, Text } from '@fatlama/llama-library'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const Time = styled(Text)`
  font-size: 12px;
  color: ${getColor('gsCoal')};
  line-height: 14px;
`

const ReadIcon = ({
  isRead,
  isMyMessage,
  createdAt
}: {
  isRead: boolean
  isMyMessage: boolean
  createdAt: any
}) => {
  const timeDisplay = dayjs(createdAt).isBefore(dayjs(), 'day')
    ? dayjs(createdAt).utc().format('D MMM')
    : dayjs(createdAt).utc().format('HH:mm')

  return (
    <Flex justifyContent='flex-end' alignItems='center'>
      <Time mr={isMyMessage ? '8px' : 0}>{timeDisplay}</Time>

      <Icon icon={isRead ? 'checkGreen' : 'checkGrey'} width={9} height={7} />
      <Icon icon={isRead ? 'checkGreen' : 'checkGrey'} width={9} height={7} />
    </Flex>
  )
}

export default ReadIcon
