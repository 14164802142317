import React from "react";
import styled from "styled-components";
import { Text, Box } from "@fatlama/llama-library";

const ClickableBox = styled(Box)`
  cursor: pointer;
`;
const Logout = () => {
  const logout = async () => {
    localStorage.clear();
    window?.location.reload();
  };
  return (
    <ClickableBox onClick={logout}>
      <Text bold color="white" cursor="pointer">
        Logout
      </Text>
    </ClickableBox>
  );
};

export default Logout;
