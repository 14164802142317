import React from 'react'
import { Flex, Text } from '@fatlama/llama-library'

const Items = () => {
  return (
    <Flex flexDirection='column'>
      <Text>Items</Text>
    </Flex>
  )
}

export default Items
