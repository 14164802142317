import React from "react";
import formatWithCurrency from "../../helpers/formatWithCurrency";
import { Box, Text } from "@fatlama/llama-library";
import { OrderDataType } from "../../interfaces/index";
import styled from "styled-components";
import { isProd } from "../../utils/env";
import { getBaseurl } from "../../helpers/environment-helper";

const TH = styled.th`
  font-size: 14px;
  width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  max-width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  min-width: ${(props: { width?: string; minWidth?: string }) =>
    props.minWidth || "auto"};
` as any;

const TD = styled.td`
  font-size: 14px;

  width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  max-width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  min-width: ${(props: { width?: string; minWidth?: string }) =>
    props.minWidth || "auto"};
` as any;

const Image = styled.img`
  border-radius: 8px;
  margin-right: 16px;
  object-fit: contain;
`;

const CurrentRentalItems = ({ order }: { order: OrderDataType }) => {
  const { items } = order;

  const currentBaseUrl = getBaseurl();
  const frontendUrl = isProd
    ? !currentBaseUrl || currentBaseUrl === "https://api.hygglo.se/api/admin"
      ? "https://fatllama.com"
      : "https://next.fatllama.com"
    : "http://local.fatllama.com:3000";

  return (
    <>
      <table>
        <tbody>
          <tr>
            <TH>Item Name</TH>
            <TH>Image</TH>

            <TH>Item ID</TH>

            <TH width="90px">Item value(s)</TH>
          </tr>
          {items.map((item: any) => {
            const {
              itemName,
              itemId,
              estimatedValue,
              systemEstimatedValue,
              imgUrls,
            } = item;
            return (
              <tr>
                <TD>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${frontendUrl}/uk/i/${item.slug}`}
                  >
                    <Text color="whale" small mb={0}>
                      {itemName}
                    </Text>
                  </a>
                </TD>
                <TD>
                  <Image width="50px" height="35px" src={imgUrls[0]} />
                </TD>

                <TD>{itemId}</TD>

                <TD width="90px">
                  {estimatedValue || systemEstimatedValue
                    ? formatWithCurrency(
                        estimatedValue || systemEstimatedValue,
                        order.currency
                      )
                    : "N/A"}
                </TD>
              </tr>
            );
          })}
          <tr>
            <TD></TD>
            <TD></TD>
            {/* <TD></TD> */}
            <TD>
              <Box>
                <Text small mb={0} color="copyTwo">
                  Total
                </Text>
              </Box>
            </TD>

            <TD width="90px">
              <Text small mb={1}>
                {formatWithCurrency(order.totalEstimatedValue, order.currency)}
              </Text>
            </TD>
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default CurrentRentalItems;
