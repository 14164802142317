import dayjs from 'dayjs'

const datesToDays = (fromDate: any, toDate: any) => dayjs(toDate).diff(fromDate, 'day')
const fatLamaPercentBorrower = 0.25
const fatLamaPercentLender = 0.25

const smartPriceOld = (priceObj: any) => {
  // Takes per day price for monthly and weekly
  const { monthlyPrice, weeklyPrice, dailyPrice, days } = priceObj
  let price
  if (!weeklyPrice && !monthlyPrice) {
    return dailyPrice
  }
  if (monthlyPrice) {
    if (days <= 7) {
      price = dailyPrice - ((dailyPrice - weeklyPrice) / 6) * (days - 1)
    } else if (days <= 30 && days > 7) {
      price = weeklyPrice - ((weeklyPrice - monthlyPrice) / 23) * (days - 7)
    } else {
      price = monthlyPrice
    }
  } else {
    const gradient = Math.log(dailyPrice / weeklyPrice) / Math.log(7)
    if (days > 30) {
      price = dailyPrice / Math.pow(30, gradient)
    } else {
      price = dailyPrice / Math.pow(days, gradient)
    }
  }
  return price
}

const smartPrice = (priceObj: any) => {
  // Takes per day price for monthly and weekly
  const { monthlyPrice, weeklyPrice, dailyPrice, days } = priceObj
  const weekboundary = 6

  if (days <= 30 && days > weekboundary && weeklyPrice) {
    return weeklyPrice
  }
  if (days > 30 && monthlyPrice) {
    return monthlyPrice
  }
  if (days > 30 && weeklyPrice) {
    return weeklyPrice
  }
  return dailyPrice
}

// calculates a range of fees, costs and earnings for a particular transaction
// item is optional but means you will get NaN values for some results.
const calculateTotalPayment = (state: any) => {
  // Legacy calculation for old apps pre fee rise July 2020
  const { item, transaction, sliderDays } = state
  // the item price either comes from item table on pre-transaction or
  // from transaction table on post-transaction
  // PRICE SHOULD BE IN PENCE
  const days = datesToDays(new Date(transaction.fromDate), new Date(transaction.toDate))

  const isLegacyPricingModel =
    state.isLegacyPricingModel ||
    (transaction && transaction.tags && transaction.tags.isLegacyPricingModel)

  let dailyPrice
  dailyPrice =
    typeof transaction.price === 'number'
      ? transaction.price
      : smartPrice({
          dailyPrice: item.price,
          weeklyPrice: item.weeklyPrice,
          monthlyPrice: item.monthlyPrice,
          // Because Rich had a shocker we now have this flag if the transaction is setting the weekly price as rentals that are 7 days rather than 8
          isSevenDayPricingModel: state.isSevenDayPricingModel,
          days: sliderDays || days
        })

  // Legacy calculation for old apps pre fee rise July 2020
  if (isLegacyPricingModel) {
    dailyPrice =
      typeof transaction.price === 'number'
        ? transaction.price
        : smartPriceOld({
            dailyPrice: item.price,
            weeklyPrice: item.weeklyPrice,
            monthlyPrice: item.monthlyPrice,
            days: sliderDays || days
          })
  }

  const rentalCost = dailyPrice * days
  const rentalCostNoSmartPricing = item ? item.price * days : transaction.price * days
  const borrowerFatLamaFeePreMin = rentalCost * fatLamaPercentBorrower

  let borrowerFatLamaFee = borrowerFatLamaFeePreMin < 500 ? 500 : borrowerFatLamaFeePreMin
  let lenderFatLamaFee = rentalCost * fatLamaPercentLender

  // Legacy calculation for old apps pre fee rise July 2020
  if (isLegacyPricingModel) {
    borrowerFatLamaFee = rentalCost * 0.15
    lenderFatLamaFee = rentalCost * 0.15
  }

  const borrowerTotalCost = rentalCost + borrowerFatLamaFee
  const lenderEarnings = rentalCost - lenderFatLamaFee
  const fatLamaTotalFee = borrowerFatLamaFee + lenderFatLamaFee
  const percentSaving = item ? (1 - dailyPrice / item.price) * 100 : 0
  const multiDaySaving = item ? (item.price - dailyPrice) * days : 0
  // If you buy something for £100 pre VAT, then the VAT is £20 on top.
  // Overall the VAT is 20/120 or 1/6 of the total cost.
  const vat = Number(lenderFatLamaFee) / 6
  return {
    ...state,
    ...{
      days,
      rentalCostNoSmartPricing,
      rentalCost,
      dailyPrice,
      dailyPriceNotRounded: dailyPrice,
      lenderFatLamaFee,
      lenderEarnings,
      borrowerFatLamaFee,
      borrowerTotalCost,
      fatLamaTotalFee,
      percentSaving,
      multiDaySaving,
      vat
    }
  }
}

export default calculateTotalPayment
