import React from "react";
import { Flex, H3 } from "@fatlama/llama-library";

import { SectionCollapsableBlock } from "./Components";
import { UserStats } from "../../interfaces/index";
import styled from "styled-components";
import { isEmpty, prop } from "ramda";

const TH = styled.th`
  font-size: 14px;
  width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  max-width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  min-width: ${(props: { width?: string; minWidth?: string }) =>
    props.minWidth || "auto"};
` as any;

const TD = styled.td`
  font-size: 14px;

  width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  max-width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  min-width: ${(props: { width?: string; minWidth?: string }) =>
    props.minWidth || "auto"};
` as any;

const UserStatsComponent = ({ stats }: { stats?: UserStats }): any => {
  if (!stats || isEmpty(stats)) {
    return null;
  }
  const keysWithCustomer = Object.keys(stats).filter((key) =>
    key.includes("customer")
  );

  return (
    <Flex flexDirection="column">
      <SectionCollapsableBlock mb={3} Title={<H3>Customer stats</H3>}>
        <table>
          <tbody>
            {keysWithCustomer.map((key, index) => {
              return (
                <tr>
                  <TH>{key}</TH>
                  <TD>{prop(key, stats)}</TD>
                </tr>
              );
            })}
          </tbody>
        </table>
      </SectionCollapsableBlock>
    </Flex>
  );
};

export default UserStatsComponent;
