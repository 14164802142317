import React from 'react'
import styled from 'styled-components'
import { Flex, Box, Text, getBreakpoint, getColor, getSpace } from '@fatlama/llama-library'
import { SMALL_IMAGE_URL } from '../../constants/imgUrls'
import MessageBubble from './MessageBubble'
import { Message, Conversation as ConversationI } from '../../interfaces/index'
import OfferBubble from './OfferBubble'
import { getItemUrl } from '../../helpers/getItemUrl'

const AreTwoDatesWithinAnHour = (date1: string, date2: string): boolean =>
  Boolean(
    (((new Date(date1) as any) - (new Date(date2) as any)) as any) < 60 * 60 * 1000 &&
      (((new Date(date1) as any) - (new Date(date2) as any)) as any) > -(60 * 60 * 1000)
  )

const ItemImage = styled(Box)`
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 4px;
  background-image: url(${(props) => props.src});
  background-size: cover;
`

const LinkedItemImage = ({ image, city, itemUrl }: any) => (
  <a href={getItemUrl(city, itemUrl)}>
    <ItemImage src={image} alt='product image' />
  </a>
)

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 40px;
  min-width: 32px;
  margin-right: ${getSpace(2)};
`

const Header = styled(Flex)`
  border-bottom: 1px solid ${getColor('gsCloud')};
  justify-content: space-between;
  align-items: center;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: ${getSpace(3)};
  padding-right: ${getSpace(3)};
`

const Container = styled(Flex)`
  position: relative;
  border-right: 1px solid ${getColor('whaleLight')};
  background-color: white;
  flex-direction: column;
  height: 100%;
`
// padding-bottom: 88px;

const MessageContainer = styled(Flex)`
  align-items: end;
  flex: 1;
  flex-direction: column;
  padding-left: ${getSpace(3)};
  padding-right: ${getSpace(3)};
  max-height: 100%;
  overflow-y: scroll;
  flex-direction: column-reverse;
  @media (max-width: ${getBreakpoint(3)}) {
    padding-left: ${getSpace(2)};
    padding-right: ${getSpace(2)};
  }
`

const OverflowHidden = styled(Flex)`
  overflow: hidden;
  height: 100%;
`

const Conversation = ({
  userId,
  conversation
}: {
  userId: number
  conversation: ConversationI
}) => {
  if (!conversation) {
    return null
  }

  const { itemUrl, city, imgUrls, otherUserProfileImgUrl, otherUserFirstName } = conversation

  const image = imgUrls && imgUrls[0] && `${SMALL_IMAGE_URL}${imgUrls[0]}`

  const messages = conversation.messages ? conversation.messages.sort((a, b) => b.id - a.id) : []
  return (
    <OverflowHidden>
      <Container>
        <Header justifyContent='space-between' alignItems='center'>
          <Flex alignItems='center' cursor='pointer'>
            <ProfileImage src={otherUserProfileImgUrl} alt='profile image' />
            <Text>{otherUserFirstName}</Text>
          </Flex>

          <LinkedItemImage image={image} city={city} itemUrl={itemUrl} />
        </Header>
        <MessageContainer>
          {messages.map((message: Message, i: number) => {
            const { id, value, createdAt, read, type, details } = message
            if (type === 'offer') {
              return (
                <OfferBubble
                  key={id}
                  offer={details}
                  createdAt={createdAt}
                  userId={userId}
                  isRead={read}
                  isMyMessage={message.userId === Number(userId)}
                />
              )
            }

            const isPreviousMessageSameUser =
              messages[i + 1] &&
              messages[i + 1].type !== 'offer' &&
              messages[i + 1].userId === userId &&
              AreTwoDatesWithinAnHour(messages[i + 1].createdAt, createdAt)
            const isNextMessageSameUser =
              messages[i - 1] &&
              messages[i - 1].type !== 'offer' &&
              messages[i - 1].userId === userId &&
              AreTwoDatesWithinAnHour(createdAt, messages[i - 1].createdAt)

            return (
              <MessageBubble
                key={id}
                value={value}
                createdAt={createdAt}
                isRead={read}
                isMyMessage={message.userId === Number(userId)}
                isNextMessageSameUser={isNextMessageSameUser}
                isPreviousMessageSameUser={isPreviousMessageSameUser}
              />
            )
          })}
          <Box minHeight='24px' />
        </MessageContainer>
      </Container>
    </OverflowHidden>
  )
}

export default Conversation
