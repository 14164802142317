import styled, { css } from 'styled-components'
import { Box } from '@fatlama/llama-library'

const fadeInStyle = css`
  transition: opacity 200ms;
  opacity: ${(props: any) => (props.shouldShow ? '1' : '0')};
  z-index: ${(props: any) => (props.shouldShow ? '1' : '-10')};
  pointer-events: ${(props: any) => (props.shouldShow ? 'default' : 'none')};
`

export const FadeInBox = styled(Box)`
  ${fadeInStyle};
`

export const FadeInHeightBox = styled(Box)`
  transition: height 300ms 300ms;
  height: ${(props: any) => (props.shouldShow ? 'auto' : '0')} !important;
  ${fadeInStyle};
`

// NOTE: this was used instead of height however causes a weird effect were page was long
// height: ${ (props: any) => (props.shouldShow ? 'auto' : '0px') };
