export const getBaseurl = (): string | null => {
  return `${
    process.env.REACT_APP_API_URL || "http://localhost:5000"
  }/api/admin`;
};

export const getAdminUrl = (path?: string): string | null => {
  const currentBaseUrl = getBaseurl();
  if (currentBaseUrl?.includes("staging")) {
    return "https://staging-admin.hygglo.se" + path;
  } else {
    return "https://admin.hygglo.se" + path;
  }
};
