import React from 'react'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroller'
import { Flex, getBreakpoint, getColor, getSpace, SpinnerIcon } from '@fatlama/llama-library'
import MessageCard from './MessageCard'
import { Conversation } from '../../interfaces/index'

const OverflowYScroll = styled(Flex)`
  overflow-y: scroll;
`

const MessageList = styled(OverflowYScroll)`
  border-right: 1px solid ${getColor('whaleLight')};
  border-left: 1px solid ${getColor('whaleLight')};
  background-color: ${getColor('whaleLightestest')};
  max-width: 384px;
  padding: ${getSpace(3)};
  flex-direction: column;
  @media (max-width: ${getBreakpoint(3)}) {
    padding-top: 0px;
    max-width: 100%;
  }
`

const ConversationList = ({
  userId,
  conversations,
  setSelectedConversationId,
  selectedConversationId,
  updateConversations,
  hasMore,
  setHasMore,
  isSearch
}: any) => {
  const conversationsToShow = conversations.map((conversation: Conversation) => {
    const isUnread =
      conversation.messages &&
      conversation.messages.some((message: any) => !message.read && message.userId !== userId)
    const setActive = async () => {
      setSelectedConversationId(conversation.id)
    }
    const isActive = conversation.id === selectedConversationId
    return (
      <MessageCard
        userId={userId}
        key={conversation.id}
        setConversationActive={setActive}
        {...conversation}
        isUnread={isUnread}
        isActive={isActive}
      />
    )
  })

  if (isSearch) {
    return <MessageList>{conversationsToShow}</MessageList>
  }
  return (
    <MessageList>
      <InfiniteScroll
        pageStart={1}
        loadMore={async (page: number) => {
          setHasMore(false)
          await updateConversations(page)
        }}
        useWindow={false}
        threshold={50}
        initialLoad={true}
        hasMore={hasMore}
        loader={<SpinnerIcon variant='multi' />}
      >
        {conversationsToShow}
      </InfiniteScroll>
    </MessageList>
  )
}

export default ConversationList
