import React, { useState } from 'react'
import { Text, SpinnerIcon } from '@fatlama/llama-library'
import GetApi from '../../utils/api'
import { useSelector } from '../../state/utils'
import { getUser } from '../../state/selectors'

const LogIntoUserAccount = ({ userId }: { userId: number }) => {
  const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const user = useSelector(getUser)

  const api = GetApi()
  const onClick = async () => {
    setIsLoading(true)
    try {
      setError(undefined)

      const payload = {
        impersonatorUsername: user.email,
        impersonatedUserId: userId
        // reason: Joi.string().optional()
      }

      const res = await api.logIntoUserAccount(payload)
      const prefillEmail = res.payload.impersonationEmail
      const prefillPassword = res.payload.impersonationPassword

      if (res.success) {
        const url = `https://fatllama.com/login?redirectUrl=%2F&view=LOGIN&prefillEmail=${prefillEmail}&prefillPassword=${prefillPassword}`
        var win = window.open(url, '_blank') as any
        win.focus()
      } else {
        setError('Unable to login')
      }
    } catch (error) {
      console.log(error)
      setError('Unable to login')
    } finally {
      setIsLoading(false)
    }
  }
  if (isLoading) {
    return <SpinnerIcon />
  }
  return (
    <>
      <Text onClick={onClick} mb={0}>
        {error || 'Log into user account'}
      </Text>
    </>
  )
}

export default LogIntoUserAccount
