import { useContext } from 'react'
import { AppContext } from './Context'
import { SelectorFn } from './selectors'

export const useSelector = (selector: SelectorFn<any>): any => {
  const { getValue } = useContext(AppContext)
  return getValue(selector)
}

export const useDispatch = () => {
  const { dispatch } = useContext(AppContext)
  return dispatch
}
