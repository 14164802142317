import React, { useState } from 'react'
import styled from 'styled-components'
import formikFieldWrapper from '../Common/formikFieldWrapper'

import { Box, Flex, Text, Span, Input, Button } from '@fatlama/llama-library'
import GetApi from '../../utils/api'
import { Field, Formik, Form } from 'formik'
import Dropdown from '../Common/Dropdown'

const FullWidthForm = styled(Form)`
  width: 100%;
`
const FormInput = formikFieldWrapper(Input)

const validate = (values: any) => {
  const errors = {} as any
  if (!values.credit && values.credit !== 0) {
    errors.credit = 'Required'
  }
  if (!values.email) {
    errors.email = 'Required'
  }
  if (!values.telephone) {
    errors.telephone = 'Required'
  }
  return errors
}

const ChangeDetailsModal = ({
  user,
  closeModal,
  getCurrentUser
}: {
  user: any
  closeModal: () => void
  getCurrentUser: () => void
}) => {
  const api = GetApi()
  const [showForgottenPasswordInfo, setShowForgottenPasswordInfo] = useState(false)
  const onSubmit = async (values: any) => {
    const update = { ...values, credit: values.credit * 100 }

    if (values.email) {
      update['email'] = values.email.trim()
    }

    const res = await api.updateUser(user.id, update)
    if (res.success) {
      getCurrentUser()
    }
    const isNowFatLlama = values.signedUpWith === 'fatlama' && user.signedUpWith !== 'fatlama'
    if (isNowFatLlama) {
      setShowForgottenPasswordInfo(true)
    } else {
      closeModal()
    }
  }
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        telephone: user.telephone,
        email: user.email,
        credit: user.credit ? user.credit / 100 : 0,
        signedUpWith: user.signedUpWith
      }}
      validate={validate}
      render={(formikBag) => {
        const { isValid, isSubmitting, setFieldValue, values } = formikBag
        return (
          <FullWidthForm>
            <Flex flexDirection='column'>
              <Box mb={4}>
                <Field name='email' required={true} label='Email' component={FormInput} />
              </Box>

              <Box mb={4}>
                <Dropdown
                  label='Signed up with'
                  options={['fatlama', 'facebook', 'google']}
                  handleChange={(event: any) => {
                    setFieldValue('signedUpWith', event.target.value)
                    // setFieldTouched('cancelledBecauseOf', true, false)
                  }}
                  value={values.signedUpWith}
                />
                {showForgottenPasswordInfo && (
                  <Text small color='midNight'>
                    Now send the user to this url for them to choose their password:{' '}
                    <Span small link>
                      https://fatllama.com/password-reset
                    </Span>
                  </Text>
                )}
              </Box>
              <Box>
                <Field name='telephone' required={true} label='Telephone' component={FormInput} />
              </Box>
              <Text color='gsGraphite' mb={4} small>
                Format: +44....
              </Text>
              <Flex alignItems='flex-end'>
                <Field
                  name='credit'
                  type='number'
                  required={true}
                  label='Credit'
                  component={FormInput}
                />
              </Flex>

              <ul>
                <li>
                  <Text color='gsGraphite' mb={0} small>
                    This is the users total current credit, not credit to add.
                  </Text>
                </li>
                <li>
                  <Text color='gsGraphite' mb={4} small>
                    The credit value here is in £/$. Not pence. For £20, type 20.
                  </Text>
                </li>
              </ul>

              <Button
                variant='primary'
                type='submit'
                large
                fullWidth
                label='Submit'
                isLoading={isSubmitting}
                disabled={!isValid}
              />
            </Flex>
          </FullWidthForm>
        )
      }}
    />
  )
}

export default ChangeDetailsModal
