import { templates, phoneCallTypes } from "./constants";
import { propEq, has } from "ramda";
import React, { useState } from "react";
import { Flex, Box, Label, Input, Button, Error } from "@fatlama/llama-library";
import { Field, Formik } from "formik";
import formikFieldWrapper from "../Common/formikFieldWrapper";
import GetApi from "../../utils/api";
import Dropdown from "../Common/Dropdown";
import TextArea from "../Common/TextArea";
import { OrderDataType } from "../../interfaces/index";
import { useHistory } from "react-router-dom";
import { useSelector } from "../../state/utils";
import { getUser } from "../../state/selectors";
const COMMS_TYPES = ["text", "email", "phone"];

const FormTextArea = formikFieldWrapper(TextArea);
const FormInput = formikFieldWrapper(Input);

const validate = (commsType: string) => (values: any) => {
  let errors = {} as any;
  if (!values.textArea) {
    errors.textArea = "Required";
  }
  if (commsType === "email") {
    if (!values.subject) {
      errors.subject = "Required";
    }
  }
  return errors;
};
const Communication = ({
  order,
  userName,
  userId,
  noteContextId,
}: {
  userName?: string;
  order?: OrderDataType;
  userId: number;
  noteContextId: string;
}) => {
  const [commsType, setCommsType] = useState("email");
  const api = GetApi();
  const history = useHistory();
  const user = useSelector(getUser);

  const addHandleBarValuesToString = (string: any) => {
    if (!string) {
      return "";
    }
    if (order) {
      return string
        .replace("**BORROWER**", order.customerFirstName)
        .replace("**ITEM**", order.items[0].itemName)
        .replace("**LENDER**", order.ownerFirstName)
        .replace("**USER**", userName)
        .replace("**CURRENCY**", order ? order.currency : "**CURRENCY**");
    } else {
      return string.replace("**BORROWER**", userName);
    }
  };

  const getVisibleTemplates = () => {
    if (commsType === "phone") {
      return null;
    }
    return templates.reduce((acc: string[], template: any) => {
      if (commsType !== "text" || has("text", template)) {
        return [...acc, template.name];
      }
      return acc;
    }, []);
  };

  const updateNotes = async (note: string) => {
    const payload = {
      userId,
      text: note,
    };

    await api.addUserNote(payload);

    history.push(`${history.location.pathname}?refresh=true`);
  };

  const onSubmit = async (values: any, formik: any) => {
    let note;

    if (commsType === "text") {
      note = `Text Sent:\n\n${values.textArea}`;

      await api.sendText({ userId, body: values.textArea });
    }

    if (commsType === "email") {
      note = `Email sent:\n\n${values.textArea}`;

      await api.sendEmail({
        userId,
        body: values.textArea,
        subject: values.subject,
      });
    }

    if (commsType === "phone") {
      note = `Phone call - ${values.phoneCallType}:\n\n${values.textArea}`;
    }

    if (note) {
      await updateNotes(note);
    }

    formik.resetForm();
  };

  return (
    <Formik
      onSubmit={onSubmit}
      validate={validate(commsType)}
      initialValues={{
        templateName: undefined,
        phoneCallType: undefined,
        textArea: "",
        subject: "",
      }}
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
        errors,
        touched,
        isSubmitting,
        isValid,
        setFieldTouched,
        resetForm,
      }) => {
        const availableTemplates = getVisibleTemplates();

        const updateTemplateValues = (templateName: string) => {
          if (!availableTemplates) {
            return;
          }

          const currentTemplate = templates.find(propEq("name", templateName));
          if (commsType === "text") {
            const value = addHandleBarValuesToString(
              currentTemplate?.text || ""
            );
            setFieldValue("textArea", value);
          } else {
            const value = addHandleBarValuesToString(currentTemplate?.email);
            const subject = addHandleBarValuesToString(
              currentTemplate?.subject
            );

            setFieldValue("textArea", value);
            setFieldValue("subject", subject);
          }
        };

        return (
          <Flex flexDirection="column" mb={7}>
            <Box maxWidth="300px" mb={4}>
              <Dropdown
                label="Comms type"
                options={COMMS_TYPES}
                handleChange={(event: any) => {
                  resetForm();
                  setCommsType(event.target.value);
                }}
                value={commsType}
              />
              {commsType !== "phone" && availableTemplates && (
                <Dropdown
                  label="Templates"
                  options={availableTemplates}
                  handleChange={(event: any) => {
                    setFieldValue("templateName", event.target.value);
                    setFieldTouched("templateName", true, false);
                    updateTemplateValues(event.target.value);
                  }}
                  value={values.templateName}
                />
              )}

              {commsType === "phone" && (
                <Dropdown
                  label="Phone call summary"
                  // error={errors.commsType && touched.commsType ? errors.commsType : null}
                  options={phoneCallTypes}
                  handleChange={(event: any) => {
                    setFieldValue("phoneCallType", event.target.value);
                    setFieldTouched("phoneCallType", true, false);
                  }}
                  value={values.phoneCallType}
                />
              )}
            </Box>
            <Box>
              {commsType === "email" && (
                <Field
                  label="Email subject"
                  name="subject"
                  error={
                    errors.subject && touched.subject ? errors.subject : null
                  }
                  mb={3}
                  type="text"
                  minRows={1}
                  required={true}
                  component={FormInput}
                />
              )}
              <Label>
                {commsType === "phone"
                  ? "Notes from phone call"
                  : commsType === "email"
                  ? "Email body"
                  : "Text message"}
              </Label>
              <Field
                name="textArea"
                type="text"
                style={{ height: "200px" }}
                minRows={5}
                maxRows={8}
                required={true}
                onChange={(e: any) => {
                  setFieldValue("textArea", e.target.value);
                  setFieldTouched("textArea", true, false);
                }}
                component={FormTextArea}
              />
              {errors.textArea && touched.textArea ? (
                <Error>{errors.textArea}</Error>
              ) : null}
              <Button
                // variant='secondary'
                onClick={() => {
                  setFieldTouched("textArea", true);
                  setFieldTouched("phoneCallType", true);
                  setFieldTouched("subject", true);

                  handleSubmit();
                }}
                large
                fullWidth
                label="Send"
                isLoading={isSubmitting}
                disabled={!isValid}
              />
            </Box>
          </Flex>
        );
      }}
    </Formik>
  );
};

export default Communication;
