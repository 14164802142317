import React from "react";
import { Flex, Text } from "@fatlama/llama-library";
import styled from "styled-components";
import { RentalAbilityPause, DuplicatedUserType } from "../../interfaces";

const AnimatedHeight = styled(Flex)`
  transition: height 0.7s ease;
`;

const UserFlaggedBanner = ({
  rentalAbilityPause,
  duplicateAccounts,
}: {
  rentalAbilityPause: RentalAbilityPause | null;
  duplicateAccounts?: Record<number, DuplicatedUserType> | null;
}) => {
  const rentalPausedDuplicateMatch =
    duplicateAccounts &&
    rentalAbilityPause &&
    rentalAbilityPause.duplicateUserId &&
    duplicateAccounts[rentalAbilityPause.duplicateUserId];
  if (rentalAbilityPause?.duplicateUserId && !rentalPausedDuplicateMatch) {
    return null;
  }
  return (
    <>
      <a href={rentalPausedDuplicateMatch ? "#duplicates" : ""}>
        <AnimatedHeight
          alignItems="center"
          bg="pomNeon"
          px={3}
          height={rentalAbilityPause ? 60 : 0}
        >
          {rentalAbilityPause && (
            <>
              <Text bold mb={0} color="white">
                Rental ability paused!
              </Text>
              <Text small mb={0} ml={1} color="white">
                {rentalPausedDuplicateMatch
                  ? `This user has been matched to a duplicate with (${
                      duplicateAccounts[rentalAbilityPause.duplicateUserId]
                        .rentalAbilityPause?.pausedBecause
                    })! CLICK HERE and validate whether this account should have the same restrictions`
                  : `Restricted user - ${rentalAbilityPause.pausedBecause}`}
              </Text>
            </>
          )}
        </AnimatedHeight>
      </a>
    </>
  );
};

export default UserFlaggedBanner;
