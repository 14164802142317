import { useState, useEffect } from 'react'

function getWindowDimensions() {
  if (typeof window === 'undefined') {
    return {}
  }
  const { innerWidth: width, innerHeight: height } = window
  return {
    width: Math.floor(width),
    height: Math.floor(height)
  }
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    const handleResize = () => {
      const dimensions = getWindowDimensions()

      setWindowDimensions(dimensions)
    }
    if (typeof window === 'undefined') {
      return undefined
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export default useWindowDimensions
