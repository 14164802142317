import { useState, useEffect } from "react";
import GetApi from "../../../utils/api";

const useUsers = ({
  borrowerId,
  lenderId,
}: {
  borrowerId?: number;
  lenderId?: number;
}) => {
  const [borrower, setBorrower] = useState<any>();
  const [lender, setLender] = useState<any>();
  const [isLoadingBorrower, setIsLoadingBorrower] = useState<boolean>(false);
  const [isLoadingLender, setIsLoadingLender] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const api = GetApi();

  const getBorrower = async (userId: number) => {
    try {
      setError(undefined);
      setIsLoadingBorrower(true);
      const res = await api.getUser(userId);
      console.log(res.payload, "borrower", userId);
      const user = res.payload;
      setBorrower(user);
    } catch (error) {
      console.log("erro", error);
      setError("Cannot load borrower details");
    } finally {
      setIsLoadingBorrower(false);
    }
  };

  const getLender = async (userId: number) => {
    try {
      setError(undefined);
      setIsLoadingLender(true);
      const res = await api.getUser(userId);
      console.log(res.payload, "lender", userId);

      const user = res.payload;
      setLender(user);
    } catch (error) {
      setError("Cannot load lender details");
    } finally {
      setIsLoadingLender(false);
    }
  };

  useEffect(() => {
    if (borrowerId) {
      getBorrower(borrowerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [borrowerId]);

  useEffect(() => {
    if (lenderId) {
      getLender(lenderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lenderId]);

  return {
    borrower,
    lender,
    isLoadingBorrower,
    isLoadingLender,
    error,
  };
};

export default useUsers;
