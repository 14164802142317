import React from "react";
import { Flex, H3 } from "@fatlama/llama-library";
import dayjs from "dayjs";
import { SectionCollapsableBlock } from "./Components";
import { AdminEvent } from "../../interfaces/index";
import styled from "styled-components";

const TH = styled.th`
  font-size: 14px;
  width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  max-width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  min-width: ${(props: { width?: string; minWidth?: string }) =>
    props.minWidth || "auto"};
` as any;

const TD = styled.td`
  font-size: 14px;

  width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  max-width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  min-width: ${(props: { width?: string; minWidth?: string }) =>
    props.minWidth || "auto"};
` as any;

const AdminEvents = ({ events }: { events: AdminEvent[] }): any => {
  if (!events || events.length === 0) {
    return null;
  }
  return (
    <Flex flexDirection="column">
      <SectionCollapsableBlock mb={3}>
        <H3>Admin events for Order</H3>

        <table>
          <tbody>
            <tr>
              <TH>Type</TH>
              <TH>Admin name</TH>

              <TH>Description</TH>

              <TH>Timestamp</TH>
            </tr>

            {events.map((event, index) => {
              return (
                <tr>
                  <TD>{event.type}</TD>
                  <TD>{event.adminName}</TD>
                  <TD>{event.description}</TD>
                  <TD>
                    {dayjs(event.createdAt).utc().format("D MMM YY, HH:mm")}
                  </TD>
                </tr>
              );
            })}
          </tbody>
        </table>
      </SectionCollapsableBlock>
    </Flex>
  );
};

export default AdminEvents;
