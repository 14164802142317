import React from "react";
import dayjs from "dayjs";
import { Box, H2, Text, Hr, Flex, Span, Button } from "@fatlama/llama-library";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Page } from "../../interfaces";
import utc from "dayjs/plugin/utc";

import ClickToCopy from "../Common/ClickToCopy";

dayjs.extend(utc);

const TitleValuePair = ({ title, value }: { title: string; value: any }) => {
  return (
    <Box width="50%" mb={3}>
      <Text small bold mb={0} color="copyTwo">
        {title}
      </Text>
      <Text small mb={0}>
        {value}
      </Text>
    </Box>
  );
};

const AddressSpan = ({ address }: any) => {
  if (!address) {
    return null;
  }
  return (
    <>
      <Text mb={0}>{address.line1}</Text>
      <Text mb={0}>{address.line2}</Text>
      <Text mb={0}> {address.city || ""}</Text>
      <Text mb={0}>{address.postalCode}</Text>
    </>
  );
};

const Email = styled(H2)`
  word-break: break-all;
`;

const Summary = ({
  user,
  addresses,
  analytics,
}: {
  user: any;
  addresses: any;
  analytics: any;
}) => {
  const latestAddress = addresses[addresses.length - 1];
  const { totalSpent, totalRequests, completedRentals } = analytics;
  return (
    <Box minWidth="300px" width="40%" pr={6}>
      <Email mb={0}>
        <ClickToCopy text={user.email}>{user.email}</ClickToCopy>{" "}
        <ClickToCopy text={user.id}>({user.id})</ClickToCopy>
      </Email>

      <Text
        mb={2}
        color={user.hasSystemGeneratedPassword ? "pomMute" : "copyTwo"}
      >
        {user.hasSystemGeneratedPassword
          ? "(Account not activated)"
          : `Customer since ${dayjs(user.createdAt)
              .utc()
              .format("D MMM YYYY")}`}
      </Text>
      <Hr mb={2} />

      <TitleValuePair
        title="Name"
        value={`${user.firstName ? user.firstName : ""} ${
          user.lastName ? user.lastName : ""
        }`}
      />
      <TitleValuePair
        title="Telephone"
        value={user.telephone || "No telephone yet"}
      />
      <Flex>
        <TitleValuePair
          title="Deleted"
          value={user.deletedAt ? <Span color="pomNeon">Yes</Span> : "No"}
        />
        <TitleValuePair
          title="Paused"
          value={user.paused ? <Span color="pomNeon">Yes</Span> : "No"}
        />
      </Flex>
      <Hr mb={3} />

      <TitleValuePair title="Total Spent" value={"£" + totalSpent / 100} />
      <Flex>
        <TitleValuePair title="Completed Req" value={completedRentals} />

        <TitleValuePair title="Total Req" value={totalRequests} />
      </Flex>
      <Hr mb={3} />

      {latestAddress && (
        <TitleValuePair
          title="Address"
          value={<AddressSpan address={latestAddress} />}
        />
      )}
      <TitleValuePair title="Logged in with" value={user.signedUpWith} />
      <TitleValuePair title="Credit" value={`£${user.credit / 100}`} />

      <Box>
        <Link to={`${Page.conversations}/${user.id}`}>
          <Button variant="secondary" label="See conversations" />
        </Link>
      </Box>
    </Box>
  );
};

export default Summary;
