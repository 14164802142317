import React, { useState } from "react";
import styled from "styled-components";
import {
  Flex,
  Button,
  Modal,
  H3,
  Text,
  getSpace,
  Error,
} from "@fatlama/llama-library";
import { RequirementType } from "../../interfaces";
import CheckboxComponent from "../Common/CheckBox";
import { keys, any, values } from "ramda";
import GetApi from "../../utils/api";
import { useHistory, useLocation } from "react-router-dom";
import { VerificationItem } from "../../interfaces";

const extraDocumentRequirementOptions = [
  RequirementType.HAS_PROOF_OF_ADDRESS,
  RequirementType.HAS_PROOF_OF_EMPLOYMENT,
  RequirementType.HAS_ONLINE_PRESENCE_LINK,
  RequirementType.HAS_IDENTITY_DOC,
  RequirementType.HAS_VIDEO_SELFIE,
];

const docTypeToText: Record<any, string> = {
  [RequirementType.HAS_PROOF_OF_ADDRESS]: "Proof of address",
  [RequirementType.HAS_PROOF_OF_EMPLOYMENT]: "Proof of employment",
  [RequirementType.HAS_ONLINE_PRESENCE_LINK]: "Social link",
  [RequirementType.HAS_IDENTITY_DOC]: "Identify documents",
  [RequirementType.HAS_VIDEO_SELFIE]: "Video selfie",
};

const DocRow = styled(Flex)`
  pointer: cursor;
  align-items: center;
  margin-bottom: ${getSpace(3)};
`;

const RequestExtraDocs = ({
  userVerifications,
}: {
  userVerifications: VerificationItem[];
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const api = GetApi();
  const history = useHistory();
  let { pathname } = useLocation() as any;

  const orderId = Number(pathname.split("/")[2]);

  const possibleDocumentsAvailableForRequest = extraDocumentRequirementOptions.filter(
    (doc) => {
      return userVerifications.find((v) => v.type === doc) === undefined;
    }
  );

  const initialCheckedObject = possibleDocumentsAvailableForRequest.reduce(
    (acc: any, doc: RequirementType) => ({ ...acc, [doc]: false }),
    {}
  );
  const [checkedReqs, setCheckedReqs] = useState(initialCheckedObject);

  const requestExtraDoc = async () => {
    try {
      setIsLoading(true);
      setError(undefined);

      const requirementsToAdd = keys(checkedReqs).reduce(
        (acc: RequirementType[], reqType: RequirementType) => {
          return Boolean(checkedReqs[reqType]) ? [...acc, reqType] : acc;
        },
        []
      );

      const payload = { requirementTypes: requirementsToAdd, orderId } as any;

      const res = await api.addExtraRequirements(payload);
      if (res.success) {
        history.push(`${history.location.pathname}?refresh=${Math.random()}`);
        setModalOpen(false);
      } else {
        setError("Unable to add document req");
      }
    } catch (error) {
      setError("Unable to add document req");
    } finally {
      setIsLoading(false);
      setCheckedReqs(initialCheckedObject);
    }
  };

  if (possibleDocumentsAvailableForRequest.length === 0) {
    return null;
  }
  return (
    <Flex mb={3}>
      <Button
        fullWidth
        label="Request docs"
        variant="secondary"
        onClick={() => setModalOpen(true)}
      />

      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <Flex px={3} py={2} flexDirection="column">
          <H3 mb={2}>Request additional docs</H3>
          {keys(checkedReqs).map((req: RequirementType) => {
            return (
              <DocRow
                onClick={() =>
                  setCheckedReqs({
                    ...checkedReqs,
                    [req]: !checkedReqs[req],
                  })
                }
              >
                <CheckboxComponent checked={checkedReqs[req]} />
                <Text ml={2} mb={0}>
                  {docTypeToText[req]}
                </Text>
              </DocRow>
            );
          })}
          <Button
            isLoading={isLoading}
            disabled={!any(Boolean, values(checkedReqs))}
            alert
            variant="primary"
            onClick={requestExtraDoc}
            label="Request"
          />

          {error && <Error>{error}</Error>}
        </Flex>
      </Modal>
    </Flex>
  );
};

export default RequestExtraDocs;
