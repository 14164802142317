import get from 'lodash/get'
import { State } from './types'
import { PartnershipAdminUser, Partnership } from '../interfaces'

export type SelectorFn<T> = (state: State) => T

export const getUser: SelectorFn<PartnershipAdminUser | undefined> = (state: State) =>
  get(state, ['user'])

export const getPartnership: SelectorFn<Partnership | undefined> = (state: State) =>
  get(state, ['partnership'])

export const getPartnershipName: SelectorFn<string | undefined> = (state: State) =>
  get(state, ['partnership', 'name'])

export const getHasLoggedOut: SelectorFn<boolean> = (state: State): boolean =>
  get(state, ['hasLoggedOut'])
