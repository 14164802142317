import React from 'react'
import styled from 'styled-components'
import { Box } from '@fatlama/llama-library'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const ResultsContainer = styled(Box)`
  flex-grow: 1;
  overflow: auto;

  border-radius: 0px 8px 8px 8px;
  tbody > tr {
    height: 60px;
  }
`

const Results = ({ results }: { results: any[] }) => {
  const history = useHistory()

  const onRowClick = (customer: any) => {
    history.push(`/customers/${customer.id}`)
  }
  return (
    <ResultsContainer>
      <table className='clickable-rows'>
        <thead>
          <tr>
            <th>Email</th>
            <th>Name</th>
            <th>Customer id</th>
            <th>Signup date</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result) => {
            return (
              <tr onClick={() => onRowClick(result)}>
                <td>{result.email}</td>
                <td>
                  {result.firstName} {result.lastName}
                </td>
                <td>{result.id}</td>

                <td>{dayjs(result.createdAt).utc().format('D MMM YYYY, HH:mm')}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </ResultsContainer>
  )
}

export default Results
