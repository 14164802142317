import React, { useState } from "react";
import styled from "styled-components";
import { Flex, Box, Text, H3 } from "@fatlama/llama-library";

import { OrderDataType } from "../../interfaces";

import RentalAbilityPause from "./RentalAbilityPause";
interface Props {
  order: OrderDataType;
}

const RentalAbilityPauseSection = ({ order }: Props) => {
  const rentalAbilityPause = order.rentalAbilityPause;
  return (
    <Box>
      <H3 mb={3}>Pause rental ability</H3>
      <Flex mb={3}>
        {!rentalAbilityPause ? (
          <Text>
            This user currently has unrestricted access to the platform within
            our normal rules.
          </Text>
        ) : (
          <Flex>
            <Text color="pomMute">
              This user has a restriction of {rentalAbilityPause?.pausedBecause}{" "}
              {rentalAbilityPause.duplicateUserId
                ? "This is due to a duplicate match. Please confirm the block by submitting below."
                : ""}
            </Text>
          </Flex>
        )}
      </Flex>

      <RentalAbilityPause
        userId={order.customerId}
        current={rentalAbilityPause}
      />
    </Box>
  );
};

export default RentalAbilityPauseSection;
