import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Flex, Box, Text, getColor } from "@fatlama/llama-library";
import { pipe, split, last, head } from "ramda";
import PDF from "../Common/PDF";
import Icon from "../Common/Icon";

const ClickableText = styled(Text)`
  cursor: pointer;
  color: ${getColor("whale")};
  margin-top: 10px;
  margin-bottom: 10px;
`;

const DocumentImageComponent = styled.img`
  object-fit: contain;
  ${({ maxHeight }: any) => `max-height: ${maxHeight}`};
  width: 100%;
` as any;

const getExtension: (filePath: string) => string = pipe(
  split("?"),
  head,
  split("."),
  last
);

const ImageDisplay = (props: any) => {
  const { src, defaultAsPdf } = props;
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [displayAsPdf, setDisplayAsPdf] = useState(Boolean(defaultAsPdf));

  const switchDisplayMode = useCallback(
    () => setDisplayAsPdf((asPdf) => !asPdf),
    []
  );

  useEffect(() => {
    if (!src) {
      return;
    }

    const extension = getExtension(src).toLowerCase();
    setDisplayAsPdf(extension === "pdf" || Boolean(defaultAsPdf));
  }, [src]);

  if (!src) {
    return null;
  }

  if (src.includes("/fl-verify.s3.eu-west-1.amazonaws.com/videos")) {
    return (
      <Flex flexDirection="column">
        <Box mb={2}>
          <video height="400px" width="100%" controls>
            {/* <source src={'https://file-examples-com.github.io/uploads/2018/04/file_example_MOV_480_700kB.mov'} type="video/mp4" /> */}
            <source src={src} type="video/mp4" />
          </video>
        </Box>
      </Flex>
    );
  }

  return (
    <>
      {displayAsPdf ? (
        <Box height={400} bg="gsCloud">
          <PDF height={400} src={src} />
        </Box>
      ) : (
        <Flex
          bg="gsCloud"
          alignItems="center"
          justifyContent="center"
          zIndex={isFullScreen ? 20 : 0}
          top={0}
          bottom={0}
          left={0}
          right={0}
          height={isFullScreen ? "100%" : "400px"}
          width={isFullScreen ? "100%" : "auto"}
          position={isFullScreen ? "fixed" : "relative"}
        >
          <DocumentImageComponent
            src={src}
            maxHeight={isFullScreen ? "100%" : "400px"}
          />
          <Box
            bg="gsGraphite"
            width="auto"
            px={2}
            py={1}
            position="absolute"
            bottom={0}
            left={0}
          >
            <Icon
              icon={isFullScreen ? "fullscreen-2" : "fullscreen-1"}
              small
              mb={0}
              color="white"
              onClick={() => setIsFullScreen(!isFullScreen)}
            />
          </Box>
        </Flex>
      )}
      <ClickableText small onClick={switchDisplayMode} c="pointer">
        Not displaying correctly? Click here to display as{" "}
        {displayAsPdf ? "image" : "PDF"}
      </ClickableText>
    </>
  );
};

export default ImageDisplay;
