import React from "react";

import { OrderStatusType, TransactionStatus } from "../../interfaces/index";
import styled from "styled-components";
import { Text, getColor, Flex } from "@fatlama/llama-library";

const StatusPill = styled(Flex)`
  border: 1px solid ${(props) => getColor(props.borderColor)};
  box-sizing: border-box;
  align-items: center;
  border-radius: 16px;
  width: 140px;
  height: 30px;
  justify-content: center;
`;

const RentalStatus = ({ orderStatus }: { orderStatus: OrderStatusType }) => {
  const stateMap = {
    [TransactionStatus.ESCROW]: "Escrow",
    [TransactionStatus.PAID]: "Paid",
    [TransactionStatus.FL_APPROVED]: "FL Appr",
    [TransactionStatus.CANCELLED]: "Canc",
    [TransactionStatus.PRE_AUTHORIZED]: "Pre Auth",
    [TransactionStatus.PRE_AUTHORIZED_CANCELLED]: "P. Auth Canc",
    [TransactionStatus.DOC_REVIEW]: "Doc Rev",
    [TransactionStatus.SECURITY_REVIEW]: "Sec Rev",
  } as any;
  const state = stateMap[orderStatus];

  return (
    <StatusPill bg="gsCloud" borderColor={"gsRat"} state={state}>
      <Text color="black" mb={0}>
        TODO: map these statuses
      </Text>
    </StatusPill>
  );
  // if ([TransactionStatus.ESCROW].includes(orderStatus)) {
  //   return (
  //     <StatusPill bg="gsCloud" borderColor={"gsRat"} state={state}>
  //       <Text color="black" mb={0}>
  //         {state}
  //       </Text>
  //     </StatusPill>
  //   );
  // }

  // if ([TransactionStatus.PAID].includes(orderStatus)) {
  //   return (
  //     <StatusPill bg="marineMuteLight" borderColor={"marineNeon"} state={state}>
  //       <Text color="marineMute" mb={0}>
  //         {state}
  //       </Text>
  //     </StatusPill>
  //   );
  // }
  // if (
  //   [TransactionStatus.FL_APPROVED, TransactionStatus.PRE_AUTHORIZED].includes(
  //     orderStatus
  //   )
  // ) {
  //   return (
  //     <StatusPill bg="orangeLight" borderColor={"orange"}>
  //       <Text color="orange" mb={0}>
  //         {state}
  //       </Text>
  //     </StatusPill>
  //   );
  // }

  // if (
  //   [
  //     TransactionStatus.CANCELLED,
  //     TransactionStatus.SECURITY_REVIEW,
  //     TransactionStatus.DOC_REVIEW,
  //     TransactionStatus.PRE_AUTHORIZED_CANCELLED,
  //   ].includes(orderStatus)
  // ) {
  //   return (
  //     <StatusPill bg="pomLight" borderColor={"pomMute"}>
  //       <Text color="pomMute" mb={0}>
  //         {state}
  //       </Text>
  //     </StatusPill>
  //   );
  // }
  // return null;
};

export default RentalStatus;
