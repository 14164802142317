import React from "react";
import { DocumentStatus } from "../../interfaces";
import { Text } from "@fatlama/llama-library";

export const ReqStatus = ({ status }: { status?: string }) => {
  switch (status) {
    case DocumentStatus.VALIDATED:
      return (
        <Text mb={0} small color="marineMute">
          Approved
        </Text>
      );
    case DocumentStatus.REJECTED:
      return (
        <Text mb={0} small color="pomMute">
          Rejcted
        </Text>
      );
    case DocumentStatus.PENDING_REVIEW:
      return (
        <Text mb={0} small color="pomMute">
          Needs review
        </Text>
      );
    case DocumentStatus.EXPIRED:
      return (
        <Text mb={0} small color="copyTwp">
          Expired
        </Text>
      );
    default:
      return (
        <Text mb={0} small color="whale">
          Requested
        </Text>
      );
  }
};
