import React from 'react'

import styled from 'styled-components'
import dayjs from 'dayjs'

import { Flex, Box, Text, getBreakpoint, getColor, getSpace } from '@fatlama/llama-library'

import { SMALL_IMAGE_URL } from '../../constants/imgUrls'
import { Conversation, Message } from '../../interfaces/index'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const Container = styled(Flex)`
  min-height: fit-content;
  border: 1px solid ${getColor('gsCloud')};
  box-shadow: ${(props) =>
    props.isActive
      ? '0px 0px 8px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05);'
      : 'none;'}
  border-radius: 8px;
  margin-bottom: ${getSpace(1)};
  cursor: pointer;
  padding: ${getSpace(2)};
  background-color: ${(props) => (props.isActive ? 'white' : getColor('whaleLightestest'))};
  @media(max-width: ${getBreakpoint(3)}) {
    &&:first-child {
      border-top: none;
    }
    padding-left: 0px;
    padding-right: 0px;
    box-shadow: none;
    background-color: white;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0;
  }
`

const ItemImage = styled(Box)`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  margin-bottom: 4px;
  background-image: url(${(props) => props.src});
  background-size: cover;
`

const NewTag = styled(Flex)`
  border-radius: 90px;
  height: 17px;
  width: 35px;
  justify-content: center;
  align-items: center;
  p {
    font-size: 8px;
    color: ${getColor('white')};
  }
`

const ItemName = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  margin-right: ${getSpace(2)};
`

const NoOverflow = styled(Flex)`
  overflow: hidden;
`
type Props = Conversation & {
  isActive: boolean
  userId: number
  setConversationActive(): void
  messages: Message[]
  isUnread: boolean
}

const MessageCard = ({
  imgUrls,
  userId,
  itemName,
  otherUserFirstName,
  otherUserProfileImgUrl,
  isActive,
  messages,
  setConversationActive,
  lastMessageAt,
  isUnread
}: Props) => {
  const image = imgUrls && imgUrls[0] ? `${SMALL_IMAGE_URL}${imgUrls[0]}` : otherUserProfileImgUrl
  const timeDisplay = dayjs(lastMessageAt).isBefore(dayjs(), 'day')
    ? dayjs(lastMessageAt).utc().format('D MMM')
    : dayjs(lastMessageAt).utc().format('HH:mm')

  const messagesSorted = messages ? messages.sort((a, b) => a.id - b.id) : []
  const mostRecentMessage = messagesSorted[messagesSorted.length - 1]

  const messageValue =
    mostRecentMessage && mostRecentMessage.type === 'offer'
      ? userId === mostRecentMessage.userId
        ? 'You sent a custom offer'
        : `${otherUserFirstName} sent you a custom offer`
      : mostRecentMessage
      ? mostRecentMessage.value
      : ''

  return (
    <Container isActive={isActive} flexDirection='row' onClick={setConversationActive}>
      <Flex mr={2} minWidth='48px' width='48px' flexDirection='column' alignItems='center'>
        <ItemImage src={image} />
        {isUnread ? (
          <NewTag bg='pomNeon'>
            <Text mb={0}>Unread</Text>
          </NewTag>
        ) : null}
      </Flex>
      <NoOverflow overflow='hidden' flexDirection='column'>
        <Flex justifyContent='space-between'>
          <ItemName>{itemName || 'General Enquiry'}</ItemName>
          <Text minWidth='52px' fontSize='14px' color='copyTwo'>
            {timeDisplay}
          </Text>
        </Flex>
        <Text>{otherUserFirstName}</Text>
        <NoOverflow minHeight='20px'>
          <Text small>{messageValue}</Text>
          {/* <TruncateText small>{messageValue}</TruncateText> */}
        </NoOverflow>
      </NoOverflow>
    </Container>
  )
}

const arePropsEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.isActive === nextProps.isActive &&
    prevProps.id === nextProps.id &&
    prevProps.messages &&
    nextProps.messages &&
    prevProps.messages.length === nextProps.messages.length
  )
}

export default React.memo(MessageCard, arePropsEqual)
