import { getColor, getFont } from '@fatlama/llama-library'
import styled, { css } from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'

const focusAndActiveState = css`
  border: 1.2px solid ${getColor('whale')};
  background-color: ${getColor('white')};
`

const placeholderStyle = css`
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
  color: ${getColor('gsRat')};
  letter-spacing: 0.1px;
`

const inputTextStyle = css`
  color: ${getColor('dark')};
  font-family: ${getFont('body')};
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
`
const StyledTextareaAutosize = styled(TextareaAutosize)`
  width: calc(100% - 24px);

  resize: none;
  border-radius: 4px;
  margin: 0;
  background-color: white;
  height: 40px;

  border: 1.2px solid ${getColor('gsRat')};

  &:focus {
    ${focusAndActiveState};
  }
  &:focus-within {
    ${focusAndActiveState};
  }

  border-radius: 8px;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;

  background-color: ${getColor('white')};

  margin-bottom: 8px;
  ${inputTextStyle};
  -webkit-appearance: none;
  &::-webkit-input-placeholder {
    ${placeholderStyle};
  }
  ::placeholder {
    ${placeholderStyle};
  }
  &:focus {
    outline: none;
  }
  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:disabled {
    cursor: not-allowed;
  }
`

export default StyledTextareaAutosize
