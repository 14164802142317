const BORROWER_FEE = 125

const getPromocodeFunctions = (promocode: any) => {
  const value = promocode.replace(/\D/g, '')
  if (promocode.includes('PERCENT')) {
    const discountMultiple = 100 - Number(value)
    return {
      getNewPrice: (price: any) =>
        price <= (100 / discountMultiple) * 100
          ? 100
          : (price / BORROWER_FEE) * discountMultiple + (price - (price / BORROWER_FEE) * 100),
      getDiscount: (price: any) =>
        price <= (100 / discountMultiple) * 100
          ? price - 100
          : (price / BORROWER_FEE) * (100 - discountMultiple)
    }
  } else if (promocode.includes('FIXED')) {
    const discount = Number(value) * 100
    return {
      getNewPrice: (price: any) => (price <= discount + 100 ? 100 : price - discount),
      getDiscount: (price: any) => (price <= discount + 100 ? price - 100 : discount)
    }
  } else if (promocode.includes('LENDER')) {
    const discountMultiple = 100 - Number(value)
    return {
      getNewPrice: (price: any) =>
        price <= (100 / discountMultiple) * 100 ? 100 : (price * discountMultiple) / 100,
      getDiscount: (price: any) =>
        price <= (100 / discountMultiple) * 100
          ? price - 100
          : (price * (100 - discountMultiple)) / 100,
      transformOtherValues: (value: any) => (value * discountMultiple) / 100
    }
  }
}

export default getPromocodeFunctions
