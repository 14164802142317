import React, { useState, useRef } from 'react'
import { Flex, getColor, Button } from '@fatlama/llama-library'
import styled from 'styled-components'

const Container = styled(Flex)`
  position: relative;
  width: auto;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const PopOver = styled(Flex)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  right: 0px;
  width: auto;
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.1),
    0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  p {
    border-bottom: 1px solid ${getColor('gsCloud')};
    white-space: nowrap;
    user-select: none;
    padding: 12px;
    cursor: pointer;
  }
  p:hover {
    background-color: ${getColor('whaleLightest')};
  }
  p:last-child {
    border: none;
  }
`

const Dropdown = ({ title, ButtonOverride, children, ...rest }: any) => {
  const [showMenu, setShowMenu] = useState(false)
  const menu = useRef(null)

  const closeMenu = (event: any) => {
    if (menu && menu.current && !(menu.current as any).contains(event.target)) {
      setShowMenu(false)
      document.removeEventListener('click', closeMenu)
    }
  }

  const showMenuFunc = (event: any) => {
    event.preventDefault()
    setShowMenu(true)
    document.addEventListener('click', closeMenu)
  }

  return (
    <Container onClick={showMenu ? null : showMenuFunc}>
      {ButtonOverride || <Button {...rest} label={title} variant='secondary' />}
      {showMenu && <PopOver ref={menu}>{children}</PopOver>}
    </Container>
  )
}

export default Dropdown
