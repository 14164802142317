import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { getItemUrl } from '../../helpers/getItemUrl'
import { Text, Flex, getBreakpoint, getSpace, resSettings } from '@fatlama/llama-library'
import GetApi from '../../utils/api'

import { SMALL_IMAGE_URL } from '../../constants/imgUrls'

import { formatCurrency } from '../../helpers/formatCurrency'
import { formatPostcode } from '../../helpers/formatLocation'

const ItemImage = styled(Flex)`
  width: 160px;
  min-width: 160px;
  max-width: 160px;
  height: 120px;
  border-radius: 8px 0px 0px 8px;
  background-image: url("${(props) => SMALL_IMAGE_URL + props.src}");
  background-position: center;
  background-size: cover;
  @media(max-width: ${getBreakpoint(3)}) {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    height: 75px;
  }
`

const Clamp = styled(Flex)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: ${getSpace(2)};
  padding-bottom: 0;
  text-align: center;
  @media (max-width: ${getBreakpoint(3)}) {
    margin-bottom: 0px;
    p {
      font-size: 14px;
    }
  }
`

const getItemUrlFromValue = (value: string): string => {
  try {
    const matcher = value.includes('fatllama.com')
      ? 'fatllama.com/rentals/'
      : 'localhost:3000/rentals/'

    const urlWithCity = value.split(matcher)[1].split(' ')[0]
    const url = urlWithCity.split('/')[urlWithCity.split('/').length - 1]
    return url
  } catch (e) {
    return ''
  }
}

const ItemLinkBubble = ({ value, index, isMyMessage }: any) => {
  const [item, setItem] = useState({})
  const api = GetApi()
  useEffect(() => {
    const asyncFunc = async () => {
      try {
        const itemUrl = getItemUrlFromValue(value)
        if (!itemUrl) {
          return
        }
        const itemResponse = await api.getItemPageData(itemUrl)
        if (!itemResponse || !itemResponse.data.success) {
          return
        }
        setItem(itemResponse.data.payload.item)
      } catch (e) {
        // Sentry.captureException(e)
      }
    }
    asyncFunc()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  if (index > 30) {
    return null
  }
  const doesMessageContainItemLink =
    value && (value.includes('localhost:3000/rentals/') || value.includes('fatllama.com/rentals/'))

  if (!doesMessageContainItemLink) {
    return null
  }

  const { city, itemUrl, imgUrls, itemName, price, currency, postcode } = item as any

  return (
    <a href={getItemUrl(city, itemUrl)} target='_blank' rel='noopener noreferrer'>
      <Flex
        mb={2}
        mt='4px'
        bg={isMyMessage ? 'whaleLightest' : '#faffff'}
        borderRadius='8px'
        cursor='pointer'
      >
        <ItemImage src={imgUrls && imgUrls[0]} />
        <Flex
          p={resSettings(1, 2, 3)}
          flexDirection='column'
          justifyContent={resSettings('center', 'start', 3)}
        >
          {itemName && (
            <>
              <Clamp>
                <Text bold textAlign='left'>
                  {itemName}
                </Text>
              </Clamp>
              {/* <DesktopContainer justifyContent='space-between'> */}
              <Text>{formatCurrency(price, currency)}/day</Text>
              <Text>
                {formatPostcode(postcode || '')} ({city})
              </Text>
              {/* </DesktopContainer> */}
            </>
          )}
        </Flex>
      </Flex>
    </a>
  )
}

export default ItemLinkBubble
