import React from 'react'

import { Text, Flex, resSettings } from '@fatlama/llama-library'

const EmptyState = ({ searchTerm }: { searchTerm: string }) => {
  return (
    <Flex alignItems='center' flexDirection='column' pt={resSettings(0, 4, 3)} px={2}>
      <Flex alignItems='center' flexDirection='column'>
        <img
          width={180}
          height={180}
          src='/images/emptyStateInbox.svg'
          alt='No conversations larry llama mascot'
        />
        <Text mt={4} mb={3} bold color='black'>
          No results for "{searchTerm}"
        </Text>
        <Text textAlign='center' mb={3}>
          Remember, you can only search by item names and user first names.
        </Text>
      </Flex>
    </Flex>
  )
}

export default EmptyState
