import React from 'react'
import styled, { css } from 'styled-components'
import { Label, Box, Error, getColor, getFont } from '@fatlama/llama-library'

const focusAndActiveState = css`
  border: 1.2px solid ${getColor('whale')};
  background-color: ${getColor('white')};
`

const placeholderStyle = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  color: ${getColor('gsRat')};
  letter-spacing: 0.1px;
`

const inputTextStyle = css`
  color: ${getColor('dark')};
  font-family: ${getFont('body')};
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
`
const StyledSelect = styled.select`
  border-radius: 4px;
  margin: 0;
  background-color: white;
  height: 40px;

  border: 1.2px solid ${getColor('gsRat')};

  &:focus {
    ${focusAndActiveState};
  }
  &:focus-within {
    ${focusAndActiveState};
  }

  border-radius: 8px;
  padding-right: 12px;
  padding-left: 12px;

  background-color: ${getColor('white')};

  margin-bottom: 8px;
  ${inputTextStyle};
  -webkit-appearance: none;
  &::-webkit-input-placeholder {
    ${placeholderStyle};
  }
  ::placeholder {
    ${placeholderStyle};
  }
  &:focus {
    outline: none;
  }
  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:disabled {
    cursor: not-allowed;
  }
  width: 100%;
`

const Dropdown = ({ options, value, label, error, handleChange }: any): any => {
  const isArray = Array.isArray(options)
  const optionsItems = isArray ? options : Object.keys(options)
  const items = optionsItems.map((key: any) => {
    return { value: key, text: isArray ? key : options[key] }
  })
  return (
    <Box>
      {label && <Label>{label}</Label>}

      <StyledSelect value={value} onChange={handleChange}>
        <option value={''}>Please choose a value</option>
        {items.map((item: any) => (
          <option key={item.value} value={item.value}>
            {item.text}
          </option>
        ))}
      </StyledSelect>
      {error && <Error>{error}</Error>}
    </Box>
  )
}

export default Dropdown
