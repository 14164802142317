import styled from 'styled-components'
import React from 'react'
import Linkify from 'react-linkify'

import { Text, Flex, getBreakpoint, getColor, getSpace } from '@fatlama/llama-library'

import ReadIcon from './ReadIcon'
import ItemLinkBubble from './ItemLinkBubble'

const getBorderRadius = ({
  isMyMessage,
  isNextMessageSameUser,
  isPreviousMessageSameUser
}: {
  isMyMessage: boolean
  isNextMessageSameUser: boolean
  isPreviousMessageSameUser: boolean
}) => {
  // if (isNextMessageSameUser && isPreviousMessageSameUser) {
  //   return '4px 4px 4px 4px'
  // }

  // if (isNextMessageSameUser && !isPreviousMessageSameUser) {
  //   return '16px 16px 4px 4px'
  // }

  // if (!isNextMessageSameUser && isPreviousMessageSameUser) {
  //   return isMyMessage ? '4px 4px 4px 16px' : '4px 4px 16px 4px'
  // }

  return isMyMessage ? '16px 16px 4px 16px' : '16px 16px 16px 4px'
}

const MessageContainer = styled(Flex)`
  flex-direction: column;
  width: auto;
  min-width: 150px;
  max-width: 450px;
  padding: ${getSpace(1)};
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid ${getColor('whaleLight')};
  @media (max-width: ${getBreakpoint(3)}) {
    max-width: 80vw;
  }
  a {
    color: ${getColor('midNight')};
    cursor: pointer;
  }
`

const ValueText = styled(Text)`
  word-break: break-word;
  line-height: 20px;
  white-space: pre-wrap;
`

const MessageBubble = ({
  key,
  value,
  createdAt,

  isMyMessage,
  isNextMessageSameUser,
  isPreviousMessageSameUser,
  isRead
}: any) => {
  return (
    <Flex
      minHeight='fit-content'
      flexDirection={isMyMessage ? 'row-reverse' : 'row'}
      mb={isNextMessageSameUser ? 1 : 3}
    >
      <MessageContainer
        bg={isMyMessage ? 'whaleLight' : 'marineLight'}
        borderRadius={getBorderRadius({
          isMyMessage,
          isNextMessageSameUser,
          isPreviousMessageSameUser
        })}
      >
        <ItemLinkBubble isMyMessage={isMyMessage} index={key} value={value} />
        <ValueText>
          <Linkify
            componentDecorator={(decoratedHref: any, decoratedText: any, key: any) => (
              <a target='blank' href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {value}
          </Linkify>
        </ValueText>
        <ReadIcon createdAt={createdAt} isMyMessage={isMyMessage} isRead={isRead} />
      </MessageContainer>
    </Flex>
  )
}

export default MessageBubble
