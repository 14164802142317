import styled from 'styled-components'
import { Flex, Box } from '@fatlama/llama-library'
import React, { useEffect, useState } from 'react'
import Icon from '../Common/Icon'
const ModalContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease;
  z-index: 2;
`

const ContentContainer = styled(Box)`
  flex-basis: 40%;
  min-width: 450px;
  padding: 24px;
  background-color: #fff;
  border-radius: 16px;
  transition: 0.2s ease;
  transform: ${(props) => (props.isVisible ? 'translateY(0%);' : 'translateY(-50%);')};
`

const Modal = ({ isOpen, children, onClose }: { onClose: any; isOpen: boolean; children: any }) => {
  const [isVisible, setIsVisible] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setIsVisible(isOpen)
    }, 200)
  }, [isOpen])

  return (
    <>
      <ModalContainer id='modal' isOpen={isOpen} isVisible={isVisible}>
        <Box position='relative' width='auto'>
          <Box position='absolute' top={0} right={0} p={3} width='auto' zIndex={2}>
            <Icon height={18} width={18} icon={'close'} onClick={onClose} />
          </Box>

          <ContentContainer isVisible={isVisible}>{children}</ContentContainer>
        </Box>
      </ModalContainer>
    </>
  )
}

export default Modal
