import { useEffect, useState } from "react";
import {
  VerificationsPayload,
  VerificationItem,
  Document,
} from "../../../interfaces";
import GetApi from "../../../utils/api";

const useUserVerifications = (
  borrowerId?: number,
  orderId?: number,
  refresh?: boolean
): {
  userVerifications?: VerificationItem[];
  loadingUserVerifications: boolean;
  userDocuments: Document[];
} => {
  const [userVerifications, setUserVerifications] = useState<
    VerificationItem[]
  >([]);
  const [userDocuments, setUserDocuments] = useState<Document[]>([]);
  const [loadingUserVerifications, setLoadingUserVerifications] = useState(
    false
  );
  const api = GetApi();
  const getVerifications = async () => {
    if (!borrowerId || !orderId) {
      return;
    }
    setLoadingUserVerifications(true);
    const userVerificationsResponse = await api.getUserVerifications(orderId);

    const {
      requirementsAndDocuments,
      allDocuments,
    } = userVerificationsResponse.payload;

    setUserVerifications(requirementsAndDocuments);
    setUserDocuments(allDocuments);
    setLoadingUserVerifications(false);
  };
  useEffect(() => {
    getVerifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [borrowerId, refresh]);

  return {
    userVerifications,
    userDocuments,
    loadingUserVerifications,
  };
};

export default useUserVerifications;
