import {
  Partnership,
  TransactionStatus,
  CMSTableRental,
} from "../../interfaces/index";

export enum Tab {
  PAST = "PAST",
  ACTIVE = "ACTIVE",
  ALL = "ALL",
}

export enum CountryFilter {
  ALL = "ALL",
  UK = "UK",
  NA = "NA",
}

export enum VerificationStatusFilter {
  ALL = "ALL",
  SECURITY_REVIEW = "SECURITY_REVIEW",
  DOC_REVIEW = "DOC_REVIEW",
  NOT_COMPLETED_VERIFICATION = "NOT_COMPLETED_VERIFICATION",
}

export enum RentalDateFilter {
  PAST = "PAST",
  ACTIVE = "ACTIVE",
}

export interface QueryRequest {
  partnership: Partnership;
  filters: QueryFilters;
  sortBy: SortingCriteria;
  pageNumber: number;
  pageSize?: number;
}

export interface QueryResponse {
  txs: CMSTableRental[];
  pageNumber: number;
  total: number;
  pageCount: number;
}

export interface QueryFilters {
  txStatuses?: TransactionStatus[];
  verificationStatus?: VerificationStatusFilter;
  searchTerm?: string;
  toDate?: string;
  fromDate?: string;
  dateFilter?: RentalDateFilter;
  countryFilter?: CountryFilter;
  customerId?: number;
}

export enum SortableField {
  TX_FROM_DATE = "TX_FROM_DATE",
  SECURITY_REVIEW_DATE = "SECURITY_REVIEW_DATE",
  TX_CREATED_AT = "TX_CREATED_AT",
}

export enum SortOrder {
  ASCENDING = "ASC",
  DESCENDING = "DESC",
}

export interface SortingCriteria {
  sortByField: SortableField;
  sortOrder: SortOrder;
}

export enum CalculatedSelectField {
  BASKET_ITEM_COUNT = "basketItemCount",
}
