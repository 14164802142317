import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { width, opacity } from 'styled-system'
import GetApi from '../../utils/api'

import {
  Flex,
  Box,
  Input,
  H1,
  Text,
  getBreakpoint,
  getColor,
  resSettings,
  SpinnerIcon
} from '@fatlama/llama-library'

import ConversationComponent from './Conversation'
import ConversationList from './ConversationList'
import NoSearchResults from './NoSearchResults'
import useDebounce from '../../hooks/useDebounce'
import { Conversation } from '../../interfaces/index'

const AnimatedBox = styled(Flex)`
  transition: all 700ms ease;
  overflow: hidden;
  ${width};
  ${opacity};
`

const TitleContainer = styled(Flex)`
  transition: all 700ms ease;
  width: auto;
  @media (max-width: ${getBreakpoint(3)}) {
    width: ${(props: any) => (props.shouldShowMobile ? '130px' : 0)};
    opacity: ${(props: any) => (props.shouldShowMobile ? 1 : 0)};
  }
`

const LabelContainer = styled(Flex)`
  border-bottom: 1px solid ${getColor('whaleLight')};
  min-height: 88px;
  height: 88px;
  align-items: center;
  @media (max-width: ${getBreakpoint(3)}) {
    border-bottom: none;
  }
`

const FixedConversation = styled(Flex)`
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 4px 8px rgba(0, 0, 0, 0.05);
  z-index: 1;
  @media (max-width: ${getBreakpoint(3)}) {
    display: none;
  }
`

const HEADER_LABEL_HEIGHT = 88

const sortConversations = (conversations: Record<number, Conversation>) => {
  const sorted = Object.values(conversations).sort(
    (a, b) => (new Date(b.lastMessageAt) as any) - (new Date(a.lastMessageAt) as any)
  )
  return sorted
}

const Conversations = () => {
  const api = GetApi()
  let { userId } = useParams() as any

  const [searchTerm, setSearchTerm] = React.useState('')
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [conversations, setConversations] = useState<any[]>([])
  const sortedConversations = sortConversations(conversations)
  const [selectedConversationId, setSelectedConversationId] = useState(
    sortedConversations[0] && sortedConversations[0].id
  )
  const [hasMore, setHasMore] = useState(false)

  const currentConversationUnread =
    conversations[selectedConversationId] &&
    conversations[selectedConversationId].messages.some(
      (message: any) => !message.read && message.userId !== userId
    )

  const updateConversations = async (newPage?: number) => {
    try {
      setIsLoading(true)
      const pageToUse = newPage || page
      if (newPage) {
        setPage(newPage)
      }
      if (pageToUse > 19) {
        setIsLoading(false)
        return
      }
      const conversationsResponse = await api.getUserConversations({
        page: pageToUse,
        searchTerm,
        userId
      })
      const conversationsNew = conversationsResponse.payload.conversations

      if (searchTerm) {
        const sorted = sortConversations(conversationsNew)
        setSelectedConversationId(sorted[0] && sorted[0].id)
      }
      setConversations(conversationsNew)
      setHasMore(conversationsResponse.payload.areThereMoreConversations)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      // Sentry.captureException(e)
      setIsLoading(false)
    }
  }

  const getConversations = async () => {
    const res = await api.getUserConversations({ page, searchTerm, userId })
    setConversations(res.payload.conversations)
    setHasMore(res.payload.areThereMoreConversations)
  }

  useEffect(() => {
    getConversations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  useDebounce(updateConversations, searchTerm, 700)
  const handleSearch = (e: any) => {
    setPage(1)
    setHasMore(false)
    setSearchTerm(e.target.value || '')
  }

  return (
    <Flex flexDirection='column'>
      <LabelContainer px={3}>
        <TitleContainer shouldShowMobile={!Boolean(searchTerm)}>
          <H1 mr={3}>Inbox</H1>
        </TitleContainer>

        <Flex maxWidth={resSettings('100%', '337px', 2)} alignItems='center'>
          <Input
            borderRadius={50}
            placeholder='Seach conversations'
            onChange={handleSearch}
            value={searchTerm}
            autoComplete='off'
          />

          <AnimatedBox
            width={Boolean(searchTerm) ? '60px' : 0}
            opacity={Boolean(searchTerm) ? 1 : 0}
            onClick={() => {
              setPage(1)
              setSearchTerm('')
            }}
          >
            {Boolean(searchTerm) &&
              (isLoading ? (
                <Flex justifyContent='center' width='60px'>
                  <SpinnerIcon variant='multi' small width='60px' />
                </Flex>
              ) : (
                <Text pl={2} mb={0} link small>
                  Clear
                </Text>
              ))}
          </AnimatedBox>
        </Flex>
      </LabelContainer>
      <Flex
        height={`calc(100vh - ${HEADER_LABEL_HEIGHT}px)`}
        maxHeight={`calc(100vh - ${HEADER_LABEL_HEIGHT}px)`}
      >
        {sortedConversations && sortedConversations.length > 0 ? (
          <ConversationList
            userId={userId}
            isSearch={Boolean(searchTerm)}
            setSelectedConversationId={setSelectedConversationId}
            hasMore={hasMore}
            setHasMore={setHasMore}
            selectedConversationId={selectedConversationId}
            conversations={sortedConversations}
            updateConversations={updateConversations}
            currentConversationUnread={currentConversationUnread}
          />
        ) : (
          <Box maxWidth={resSettings('100%', 384, 3)} px={3}>
            <NoSearchResults searchTerm={searchTerm} />
          </Box>
        )}

        <FixedConversation flexDirection='column'>
          <ConversationComponent
            userId={userId}
            conversation={
              conversations[selectedConversationId as any] || Object.values(conversations)[0]
            }
          />
        </FixedConversation>
      </Flex>
    </Flex>
  )
}

export default Conversations
