import React from 'react'
import styled from 'styled-components'
import { getColor, getSpace, Text, Flex } from '@fatlama/llama-library'

const ToggleContainer = styled(Flex)`
  border-radius: 40px;
  height: 56px;
  border: 1px solid ${getColor('gsSlate')};
  padding: 4px;
  width: auto;
  background-color: white;
`

const ToggleButton = styled(Flex)`
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  padding-left: ${getSpace(3)};
  padding-right: ${getSpace(3)};
  max-width: fit-content;
  transition: background-color 300ms;
  cursor: pointer;
`

const Toggle = ({
  leftText,
  rightText,
  onLeftClick,
  onRightClick,
  leftActive
}: {
  leftText: string
  rightText: string
  onLeftClick(): void
  onRightClick(): void
  leftActive: boolean
}) => {
  return (
    <ToggleContainer>
      <ToggleButton
        minWidth='30%'
        bg={leftActive ? 'marineMuteDark' : 'transparent'}
        onClick={onLeftClick}
      >
        <Text bold mb={0} color={leftActive ? 'white' : 'copyTwo'} noWrap>
          {leftText}
        </Text>
      </ToggleButton>
      <ToggleButton
        minWidth='30%'
        bg={!leftActive ? 'marineMuteDark' : 'transparent'}
        onClick={onRightClick}
      >
        <Text bold mb={0} color={!leftActive ? 'white' : 'copyTwo'} noWrap>
          {rightText}
        </Text>
      </ToggleButton>
    </ToggleContainer>
  )
}

export const TripleToggle = ({
  leftText,
  centerText,
  rightText,
  onLeftClick,
  onCenterClick,
  onRightClick,
  leftActive,
  centerActive,
  rightActive
}: {
  leftText: string
  centerText: string
  rightText: string
  onLeftClick(): void
  onCenterClick(): void
  onRightClick(): void
  leftActive: boolean
  centerActive: boolean
  rightActive: boolean
}) => {
  return (
    <ToggleContainer>
      <ToggleButton
        minWidth='33.3%'
        bg={leftActive ? 'marineMuteDark' : 'transparent'}
        onClick={onLeftClick}
      >
        <Text bold mb={0} color={leftActive ? 'white' : 'copyTwo'}>
          {leftText}
        </Text>
      </ToggleButton>
      <ToggleButton
        minWidth='33.3%'
        bg={centerActive ? 'marineMuteDark' : 'transparent'}
        onClick={onCenterClick}
      >
        <Text bold mb={0} color={centerActive ? 'white' : 'copyTwo'}>
          {centerText}
        </Text>
      </ToggleButton>
      <ToggleButton
        minWidth='33.3%'
        bg={rightActive ? 'marineMuteDark' : 'transparent'}
        onClick={onRightClick}
      >
        <Text bold mb={0} color={rightActive ? 'white' : 'copyTwo'}>
          {rightText}
        </Text>
      </ToggleButton>
    </ToggleContainer>
  )
}

export default Toggle
