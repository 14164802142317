import React from "react";
import styled from "styled-components";
import { Box, Flex, Text, SpinnerIcon } from "@fatlama/llama-library";
import dayjs from "dayjs";
import { CMSTableRental } from "../../interfaces";
import { useHistory } from "react-router-dom";
import { getTimeSince } from "./utils";
import StatusPill from "../Common/StatusPill";
import currencyMap from "../../constants/currencyMap";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const FlagIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-left: 16px;
`;

const ResultsContainer = styled(Box)`
  flex-grow: 1;
  overflow: auto;
  border-radius: 0px 8px 8px 8px;
`;

const TH = styled.th`
  font-size: 14px;
  width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  max-width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  min-width: ${(props: { width?: string; minWidth?: string }) =>
    props.minWidth || "auto"};
` as any;

const TD = styled.td`
  font-size: 14px;
  position: relative;
  width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  max-width: ${(props: { width?: string; minWidth?: string }) =>
    props.width || "auto"};
  min-width: ${(props: { width?: string; minWidth?: string }) =>
    props.minWidth || "auto"};
` as any;

// box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05),
// 0px 2px 4px rgba(0, 0, 0, 0.05);

const TableHeader = ({
  isSecurityReviewView,
}: {
  isSecurityReviewView?: any;
}) => {
  return (
    <tr>
      <TH>ID</TH>
      <TH minWidth="70px">Dates</TH>
      <TH>Status</TH>
      {isSecurityReviewView && <TH>Time IN REVIEW</TH>}
      <TH minWidth="100px">Created</TH>
      <TH>GMV</TH>
      <TH minWidth="200px">Item Name(s)</TH>
      <TH>Renter</TH>
      <TH>Owner</TH>
    </tr>
  );
};
const Results = ({
  results,
  searchTerm,
  isSecurityReviewView,
  inAll,
  isLoading,
}: {
  results: CMSTableRental[];
  isSecurityReviewView: boolean;
  searchTerm?: string;
  inAll: boolean;
  isLoading: boolean;
}) => {
  const history = useHistory();

  if ((!results || results.length === 0) && searchTerm) {
    return (
      <ResultsContainer>
        <table className="clickable-rows">
          <tbody>
            <TableHeader />
          </tbody>
        </table>
        <Flex alignItems="center" justifyContent="center" pt="70px">
          {isLoading ? (
            <SpinnerIcon size="large" />
          ) : (
            <Text bold>No results</Text>
          )}
        </Flex>
      </ResultsContainer>
    );
  }

  return (
    <ResultsContainer>
      <table className="clickable-rows">
        <tbody>
          <TableHeader isSecurityReviewView={isSecurityReviewView} />
          {results.map((rental, index) => {
            const isCompelted =
              rental.toDate &&
              (rental.orderStatus === "COMPLETED" ||
                (rental.orderStatus === "ACTIVE" &&
                  dayjs().isAfter(rental.toDate, "day")));

            const timeCustomerInReview =
              rental.timeCustomerWaitingForReview &&
              getTimeSince(rental.timeCustomerWaitingForReview);
            return (
              <tr
                key={rental.orderId}
                onClick={() => {
                  history.push(`/rentals/${rental.orderId}`);
                }}
              >
                <TD position="relative">
                  <Flex width="auto" alignItems="center">
                    {rental.currency === "USD" && (
                      <FlagIcon src="/images/usa-icon.png" />
                    )}
                    {rental.currency === "CAD" && (
                      <FlagIcon src="/images/ca-icon.png" />
                    )}
                  </Flex>

                  <Flex flexDirection="column" alignItem="center">
                    <Text mb={0} small textAlign="center">
                      ({index + 1}) {rental.orderId}
                    </Text>
                    {isCompelted && searchTerm ? (
                      <Text small textAlign="center">
                        (complete)
                      </Text>
                    ) : null}
                  </Flex>
                </TD>

                <TD minWidth="70px">
                  <Text small>
                    {rental.fromDate
                      ? dayjs(rental.fromDate).utc().format("D MMM YY")
                      : "-"}
                  </Text>
                  <Text small>
                    {rental.toDate
                      ? dayjs(rental.toDate).utc().format("D MMM YY")
                      : "-"}
                  </Text>
                </TD>

                <TD>
                  <StatusPill
                    small
                    mb={1}
                    securityStatus={rental.securityStatus}
                  />
                  {inAll ? (
                    <>
                      <StatusPill
                        mb={1}
                        small
                        paymentStatus={rental.paymentStatus}
                      />
                      <StatusPill small orderStatus={rental.orderStatus} />
                    </>
                  ) : null}
                </TD>

                {isSecurityReviewView && (
                  <TD>
                    <Text mb={0} color="pomMute" small>
                      {timeCustomerInReview || "N/A"}
                    </Text>
                  </TD>
                )}

                <TD minWidth="100px">
                  {dayjs(rental.createdAt).utc().format("D MMM YY, HH:mm")}
                </TD>

                <TD>
                  {currencyMap[rental.currency]}
                  {rental.rentalVolume.toFixed(2)}
                </TD>

                <TD minWidth="200px">
                  {rental.itemNames.length > 1
                    ? `${rental.itemNames.length} items`
                    : rental.itemNames[0]}
                </TD>

                <TD>{rental.borrowerEmail}</TD>
                <TD>
                  <Text>{rental.lenderEmail}</Text>
                  {/* {rental.lenderNotActivated && (
                    <Text small color="pomMute">
                      (Account not activated)
                    </Text>
                  )} */}
                </TD>
              </tr>
            );
          })}
        </tbody>
      </table>
    </ResultsContainer>
  );
};

export default Results;
