import getPaymentData from './getPaymentData'

const getPaymentDetailsFromOffer = (offer: any, promocode?: string, credit?: number) => {
  const dates = { fromDate: offer.fromDate, toDate: offer.toDate }
  const price = offer.dailyPrice ? offer.dailyPrice : 0
  const totalPaymentObj = getPaymentData({
    transaction: { ...dates, price },
    promocode,
    borrowerCredit: credit,
    isSevenDayPricingModel: true
  })
  const offerPrice = totalPaymentObj.rentalCost
  const borrowerFee = totalPaymentObj.borrowerFatLamaFee
  return {
    offerPrice,
    promocodeDiscount: totalPaymentObj.promocodeDiscount,
    days: totalPaymentObj.days,
    borrowerFee,
    borrowerTotalCost: totalPaymentObj.borrowerTotalCost,
    lenderEarnings: totalPaymentObj.lenderEarnings,
    creditUsed: totalPaymentObj.creditUsed
  }
}

export default getPaymentDetailsFromOffer
