import { TAction } from "./actions";

import * as types from "./types";
import { AUTH, LOGOUT } from "../utils/apiTypes";

const reducer = (state: types.State, action: TAction): types.State => {
  const { type } = action;
  switch (type) {
    case LOGOUT.REQUEST:
      return {
        ...state,
        user: undefined,
        partnership: undefined,
      };
    case AUTH.SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
