import React, { useState } from "react";
import { Flex, H3 } from "@fatlama/llama-library";
import Notes from "../CommunicationAndNotes/Notes";

import { SectionBlock, TwoGrid } from "./Components";
import { OrderDataType } from "../../interfaces/index";
import Toggle from "../Common/Toggle";
import Communication from "../CommunicationAndNotes/Communication";

const CommunicationsAndNotes = ({ order }: { order: OrderDataType }): any => {
  const [sendingBorrower, setSendingBorrower] = useState(true);

  const userId = sendingBorrower ? order.customerId : order.ownerId;
  const title = sendingBorrower ? "Renter" : "Owner";
  return (
    <Flex flexDirection="column">
      <Flex justifyContent="center" mb={3}>
        <Toggle
          leftText="Renter"
          rightText="Owner"
          onLeftClick={() => setSendingBorrower(true)}
          onRightClick={() => setSendingBorrower(false)}
          leftActive={sendingBorrower}
        />
      </Flex>
      <TwoGrid>
        <SectionBlock mb={3}>
          <H3>User notes</H3>

          <Notes
            userId={userId}
            title={title}
            noteContextId={`TransId: ${order.orderId}`}
          />
        </SectionBlock>

        <SectionBlock mb={3}>
          <H3>Send {title} Comms</H3>
          <Communication
            userId={userId}
            userName={
              sendingBorrower ? order.customerFirstName : order.ownerFirstName
            }
            order={order}
            noteContextId={`TransId: ${order.orderId}`}
          />
        </SectionBlock>
      </TwoGrid>
    </Flex>
  );
};

export default CommunicationsAndNotes;
