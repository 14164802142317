export enum SupportedCurrency {
  GBP = 'GBP',
  USD = 'USD'
}

function numberWithCommas(x: any) {
  var parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}
const currencyMap = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  CAD: 'CA$'
}

export const formatCurrency = (
  amountPence: number,
  currency?: SupportedCurrency,
  numberDecimals?: number,
  commas?: boolean
) => {
  const amount = (amountPence / 100)?.toFixed(
    typeof numberDecimals === 'number' ? numberDecimals : 2
  )

  return `${currencyMap[currency || SupportedCurrency.GBP] || '£'}${
    commas ? numberWithCommas(amount) : amount
  }`
}

export const dayS = (numDays: number) => {
  if (numDays === 1) {
    return 'day'
  }
  return 'days'
}
