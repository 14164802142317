import React, { useState } from "react";
import styled from "styled-components";
import { VerificationItem } from "../../interfaces";
import { Flex, Box, Text, Hr, getSpace } from "@fatlama/llama-library";
import DocumentSummaryPanel from "./DocumentSummaryPanel";
import { Document, DocumentType } from "../../interfaces";
import * as R from "ramda";
import DocumentWithAction from "./DocumentDisplay";

const DocContainer = styled(Box)`
  display: grid;
  grid-column-gap: ${getSpace(3)};
  grid-row-gap: ${getSpace(4)};
  grid-template-columns: 1fr 1fr;
`;

const Documents = ({
  userVerifications,
  userId,
  orderId,
  userDocuments,
}: {
  userVerifications?: VerificationItem[];
  userDocuments?: Document[];
  userId: number;
  orderId: number;
}) => {
  const [showRejected, setShowRejected] = useState(false);
  if (!userVerifications) {
    return null;
  }

  const needsReviewDocs = (userDocuments || []).filter((doc) => {
    return doc.status === "PENDING_REVIEW";
  });
  const validatedDocs = (userDocuments || []).filter((doc) => {
    return doc.status === "VALIDATED";
  });
  const rejectedDocs = (userDocuments || []).filter((doc) => {
    return doc.status === "REJECTED";
  });
  const sortBySuspectedType = R.pipe(
    R.sortBy(R.prop("suspectedType")),
    R.reverse
  );
  return (
    <Flex>
      <Box width="260px" pr={3}>
        <DocumentSummaryPanel
          userId={userId}
          orderId={orderId}
          userVerifications={userVerifications}
        />
      </Box>
      <Flex flexDirection="column">
        {needsReviewDocs.length > 0 ? (
          <>
            <Box mb={4}>
              <Text bold>Needs Review</Text>
              <DocContainer mb={3}>
                {sortBySuspectedType(needsReviewDocs).map(
                  (document: Document) => {
                    return <DocumentWithAction document={document} />;
                  }
                )}
              </DocContainer>
            </Box>
          </>
        ) : null}

        {validatedDocs.length > 0 ? (
          <>
            <Box mb={4}>
              <Text bold>Valid documents</Text>
              <DocContainer mb={3}>
                {sortBySuspectedType(validatedDocs).map(
                  (document: Document) => {
                    return <DocumentWithAction document={document} />;
                  }
                )}
              </DocContainer>
            </Box>
          </>
        ) : null}
        {rejectedDocs.length > 0 ? (
          <>
            <Box mb={4}>
              <Text bold>Rejected documents</Text>

              <Text mb={3} link onClick={() => setShowRejected(!showRejected)}>
                Click to {showRejected ? "hide" : "show"} rejected{" "}
                {showRejected ? "↑" : "↓"}
              </Text>
              {showRejected ? (
                <DocContainer mb={3}>
                  {sortBySuspectedType(rejectedDocs).map(
                    (document: Document) => {
                      return <DocumentWithAction document={document} />;
                    }
                  )}
                </DocContainer>
              ) : null}
            </Box>
          </>
        ) : null}
        {userDocuments?.length === 0 ? (
          <Flex justifyContent="center" align="center">
            <Text small>The renter hasn't uploaded any documents yet</Text>
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default Documents;
