import React from 'react'
import styled from 'styled-components'
import { Flex, Text } from '@fatlama/llama-library'

import { useParams } from 'react-router-dom'

const FullScreenCentered = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`

const ErrorPage = () => {
  let { errorCode } = useParams() as any

  return (
    <FullScreenCentered>
      <Text>Error: {errorCode === '403' ? 'You were unaurthorized to view this' : 'Unkwnown'}</Text>
    </FullScreenCentered>
  )
}

export default ErrorPage
