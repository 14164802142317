import React from 'react'
import DateTimeRangePickerComponent from '@wojtekmaj/react-datetimerange-picker'
import styled from 'styled-components'
// react-datetimerange-picker__clock
import { Box, getSpace, getColor, Flex, Text, getFont } from '@fatlama/llama-library'

const StylingWrapper = styled(Box)`
  .react-datetimerange-picker__clock {
    display: none !important;
  }
  .react-datetimerange-picker__wrapper {
    padding: 0;
    border: none;
    margin-bottom: ${getSpace(1)};
  }
  .react-datetimerange-picker__inputGroup {
    padding-left: ${getSpace(1)};
    padding-right: ${getSpace(1)};
    padding-top: ${getSpace(1)};
    padding-bottom: ${getSpace(1)};
    border: 1px solid ${getColor('gsRat')};
    border-radius: 16px;
    margin-right: 16px;
  }
  .react-datetimerange-picker__range-divider {
    display: none;
  }
  .react-datetimerange-picker__inputGroup__year {
    ${(props: any) => props.dayOnly && `margin-right: ${getSpace(1)}`};
  }
  .react-datetimerange-picker__calendar-button {
    display: none;
  }
  .react-calendar {
    border: 1px solid ${getColor('gsRat')};
    border-radius: 16px;
    font-family: ${getFont('body')};
  }
  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    font-weight 400 !important;
  }
  .react-calendar__month-view__days__day--weekend {
    color: ${getColor('midNight')};
  }
  .calendar__navigation__label__labelText {
    font-size: 16px
  }
  .react-calendar__tile--now {
    background-color: ${getColor('whaleLighter')};
   }
   .react-calendar__tile--active {
    background-color: ${getColor('midNight')} !important;
    color: white;

   }


   .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focused {
    background-color: ${getColor('midNight')} !important;
    color: white;

   }

`
const DateTimeRangePicker = ({ value, onChange, dayOnly }: any) => {
  return (
    <StylingWrapper dayOnly={dayOnly}>
      <Flex>
        <Text width={dayOnly ? '120px' : '220px'}>From:</Text>
        <Text>To:</Text>
      </Flex>
      <DateTimeRangePickerComponent
        amPmAriaLabel='Select AM/PM'
        calendarAriaLabel='Toggle calendar'
        clearAriaLabel='Clear value'
        clearIcon={<Text mb={0}>Clear</Text>}
        locale='GB'
        format={dayOnly ? 'dd-MM-y' : 'dd-MM-y hh a'}
        rangeDivider='to'
        dayAriaLabel='Day'
        hourAriaLabel='Hour'
        maxDetail={'hour'}
        isClockOpen={false}
        onClockOpen={() => undefined}
        minuteAriaLabel='Minute'
        monthAriaLabel='Month'
        nativeInputAriaLabel='Date and time'
        onChange={onChange}
        secondAriaLabel='Second'
        value={value}
        yearAriaLabel='Year'
      />
    </StylingWrapper>
  )
}

export default DateTimeRangePicker
