import React from 'react'
import styled from 'styled-components'
import { Flex, H2, Text, getSpace, getColor, Input, SpinnerIcon } from '@fatlama/llama-library'
import { NAV_WIDTH } from '../NavSideBar/Index'

const ClearText = styled(Text)`
  cursor: pointer;
  white-space: nowrap;
  margin-left: ${getSpace(2)};
  color: ${getColor('whale')};
  margin-bottom: 0;
`

const HeaderContainer = styled(Flex)`
  flex-direction: column;
  position: fixed;
  background-color: white;
  z-index: 2;
  padding: ${getSpace(3)};
  width: calc(100% - ${NAV_WIDTH}px);
  border-bottom: 1px solid ${getColor('gsRat')};
`

const SearchInput = styled(Input)`
  border-radius: 40px;
  input::placeholder {
    font-weight: 300;
  }
`

const Header = ({
  setSearchTerm,
  id,
  isLoading,
  searchTerm
}: {
  setSearchTerm: any
  id?: string
  isLoading: boolean
  searchTerm?: string
}) => {
  const onSearch = (e: any) => {
    const value = e.target.value
    setSearchTerm(value)
  }

  return (
    <HeaderContainer id={id}>
      <H2>Customers</H2>
      <Flex>
        <SearchInput
          placeholder='Search by user email, telephone or ID...'
          value={searchTerm}
          onChange={onSearch}
        />
        <Flex minWidth='40px' maxWidth='40px' ml={2} justifyContent='center' alignItems='center'>
          {isLoading ? (
            <SpinnerIcon />
          ) : (
            <ClearText cursor='pointer' small onClick={() => setSearchTerm('')}>
              Clear
            </ClearText>
          )}
        </Flex>
      </Flex>
    </HeaderContainer>
  )
}

export default Header
