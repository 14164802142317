import React, { useEffect, useState } from "react";
import {
  Flex,
  SpinnerIcon,
  Error,
  Box,
  Text,
  getColor,
} from "@fatlama/llama-library";
import styled from "styled-components";
import GetApi from "../../utils/api";
import { path } from "ramda";

const Links = styled(Text)`
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
  a {
    color: ${getColor("whale")};
    margin-bottom: 0px;
  }
`;

const AddressSpan = ({ address }: any) => {
  if (!address) {
    return null;
  }
  return (
    <>
      <Text mb={0}>{address.line1}</Text>
      <Text mb={0}>{address.line2}</Text>
      <Text mb={0}> {address.city || ""}</Text>
      <Text mb={0}>{address.postal_code}</Text>
    </>
  );
};

const StripeData = ({ transactionId }: { transactionId: number }) => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const api = GetApi();

  const getData = async () => {
    try {
      setError(undefined);
      setIsLoading(true);

      const res = await api.getTxStripeData(transactionId);

      if (!res.success) {
        setError("Cannot get user stripe data");
        return;
      }
      const data = res.payload;
      setData(data);
    } catch (error) {
      setError("Cannot get user stripe data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  if (isLoading || !data) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <SpinnerIcon />
      </Flex>
    );
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  const {
    customerId,
    chargeId,
    numberOfCards,
    numberOfFailedChargesInLast7Days,
    billingAddress,
    radar,
    cardFingerprint,
    customer,
    matchingFingerprintCustomers,
  } = data;

  const stripeFingerprintSearchUrl =
    "https://dashboard.stripe.com/search?query=is%3Acustomers%20fingerprint%3A" +
    cardFingerprint;
  const customerName =
    path(["name"], customer) || path(["shipping", "name"], customer);
  return (
    <Flex flexDirection="column">
      <Flex>
        <Flex flexDirection="column" width={1 / 3}>
          <Box mb={4}>
            <Links>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://dashboard.stripe.com/customers/${customerId}`}
              >
                Customer Stripe
              </a>
            </Links>

            {chargeId && (
              <Links>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://dashboard.stripe.com/payments/${chargeId}/review`}
                >
                  Charge Stripe
                </a>
              </Links>
            )}

            {cardFingerprint && (
              <Links mb={4}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={stripeFingerprintSearchUrl}
                >
                  Card fingerprint check
                </a>
              </Links>
            )}

            {matchingFingerprintCustomers && (
              <Text small mb={0} color="copyTwo">
                Customers matching fingerprint:
              </Text>
            )}
            {matchingFingerprintCustomers &&
              matchingFingerprintCustomers.map((customer: any) => {
                const customerName =
                  path(["name"], customer) ||
                  path(["shipping", "name"], customer);
                return <Text>{customerName}</Text>;
              })}
          </Box>
          <Text small color="copyTwo" mb={0}>
            Failed charges in last 7 days
          </Text>
          <Text mb={3} small>
            {numberOfFailedChargesInLast7Days}
          </Text>

          <Text small color="copyTwo" mb={0}>
            Total number of cards
          </Text>
          <Text mb={3} small>
            {numberOfCards}
          </Text>
        </Flex>

        <Flex flexDirection="column" width={1 / 3}>
          <Text small mb={0} color="copyTwo">
            Cardholder name
          </Text>
          <Text mb={4}>{customerName}</Text>

          <Text small mb={0} color="copyTwo">
            Billing Address
          </Text>

          <AddressSpan address={billingAddress} />
        </Flex>

        <Flex flexDirection="column" width={1 / 3}>
          <Text bold>Fraud insights</Text>
          <Text>Risk Score: {radar.score}</Text>
          <Text>Radar message: {radar.message}</Text>
          {radar.rule && <Text>Radar rule: {radar.rule}</Text>}
        </Flex>
      </Flex>

      {/*
      <DataRow title='Deliverable' value={`${emailResponse.response.deliverable} `} />

      <DataRow title='Data Breaches' value={`${emailResponse.response.deliverable} `} /> */}

      {/* <DataRow title='User ID' value={`${borrower.user.id}`}  /> */}
    </Flex>
  );
};

export default StripeData;
