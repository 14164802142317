import React, { createContext, Dispatch, FC, useReducer } from "react";
import { TAction } from "./actions";
import { State } from "./types";
import reducer from "./reducer";
import { SelectorFn } from "./selectors";



const initialState: State = {
  hasLoggedOut: false,
  error: "",
  loading: false,
};

interface IContextProps<T> {
  getValue(selector: SelectorFn<T>): T;
  dispatch: Dispatch<TAction>;
}

export const AppContext = createContext<IContextProps<any>>({
  dispatch: () => {
    // Dispatch initial value
  },
  getValue: () => initialState,
});

const AppProvider: FC<{}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const getValue = (selector: any) => {
    return selector(state);
  };


  return (
    <AppContext.Provider value={{ getValue, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
