import React from "react";
import styled from "styled-components";
import NavSideBar from "./components/NavSideBar/Index";
import Rentals from "./components/Rentals/Index";
import Customers from "./components/Customers/Index";
import { Flex, Box } from "@fatlama/llama-library";
import { Page } from "./interfaces";
import ErrorPage from "./components/ErrorPage";
import SelectedRental from "./components/SelectedRental/Index";
import SelectedCustomer from "./components/SelectedCustomer";
import AuthSwitch from "./components/Authentication/AuthSwitch";
import Items from "./components/Items/Index";
import Conversations from "./components/Conversations/Index";
import CustomerRentals from "./components/SelectedCustomer/CustomerRentals";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

const ContentContainer = styled(Box)`
  height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
`;

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path={`${Page.error}/:errorCode`}>
          <ErrorPage />
        </Route>
        <Route>
          <AuthSwitch>
            <Flex minHeight="100vh" maxHeight="100vh">
              <NavSideBar />
              <ContentContainer id="pageContainer">
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to={Page.rentals} />}
                  />

                  <Route path={`${Page.rentals}/:idOrOfferUuid`}>
                    <SelectedRental />
                  </Route>

                  <Route path={Page.rentals}>
                    <Rentals />
                  </Route>

                  <Route path={`${Page.customers}/:id`}>
                    <SelectedCustomer />
                  </Route>
                  <Route path={`${Page.customerRentals}/:id`}>
                    <CustomerRentals />
                  </Route>

                  <Route path={`${Page.conversations}/:userId`}>
                    <Conversations />
                  </Route>

                  <Route path={Page.customers}>
                    <Customers />
                  </Route>

                  <Route path={Page.items}>
                    <Items />
                  </Route>

                  <Route path={`${Page.error}/:errorCode`}>
                    <ErrorPage />
                  </Route>
                </Switch>
              </ContentContainer>
            </Flex>
          </AuthSwitch>
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
