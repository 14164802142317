const applyCredit = (state: any) => {
  if (!state.borrowerCredit || state.borrowerCredit <= 0 || state.promocodeDiscount > 0) {
    return {
      ...state,
      ...{
        // no change
        creditUsed: 0
      }
    }
  } else if (state.borrowerCredit + 100 >= state.borrowerTotalCost) {
    return {
      ...state,
      ...{
        borrowerTotalCost: 100,
        fatLamaWalletTopUp: state.lenderEarnings - 100,
        creditUsed: state.borrowerTotalCost - 100,
        needsTransfer: true
      }
    }
  } else if (state.borrowerCredit < state.borrowerTotalCost + 100) {
    const newTotalCost = state.borrowerTotalCost - state.borrowerCredit
    const creditUsed = state.borrowerCredit - (newTotalCost < 100 ? 100 - newTotalCost : 0)
    const newFatLamaTopUp =
      newTotalCost >= state.lenderEarnings ? 0 : state.lenderEarnings - Math.round(newTotalCost) // round here because otherwise top up can be 1p too little

    return {
      ...state,
      ...{
        borrowerTotalCost: newTotalCost < 100 ? 100 : newTotalCost,
        creditUsed,
        fatLamaWalletTopUp: newFatLamaTopUp,
        needsTransfer: newFatLamaTopUp > 0.005
      }
    }
  } else {
    throw new Error('applyCredit did not receive expected parameters')
  }
}

export default applyCredit
