import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getHasLoggedOut } from "../../state/selectors";
import { Flex, SpinnerIcon } from "@fatlama/llama-library";
import GetApi from "../../utils/api";
import { useSelector } from "../../state/utils";
import { getUser } from "../../state/selectors";
import { parseCookies } from "nookies";
import get from "lodash/get";
import LoginForm from "./LoginForm";

const FullScreenCentered = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

// const Authenticator = () => {
//   const [hasAuthenticated, setHasAuthenticated] = useState(false);
//   const hasLoggedOut = useSelector(getHasLoggedOut);
//   const api = GetApi();

//   const authToken = localStorage.getItem("token");

//   console.log(authToken, "-authToken");
//   useEffect(() => {
//     const authenticate = async () => {
//       await api.auth();
//       setHasAuthenticated(true);
//     };
//     if (!hasLoggedOut && authToken) {
//       authenticate();
//     }
//   }, [api, hasLoggedOut, authToken]);

//   if (authToken && !hasAuthenticated) {
//     return (
//       <FullScreenCentered>
//         <SpinnerIcon />
//       </FullScreenCentered>
//     );
//   }

//   return <LoginForm />;
// };

const AuthSwitch = ({ children }: any) => {
  // const user = useSelector(getUser);
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    return <LoginForm />;
  }
  return children;
};

export default AuthSwitch;
