import React, { useState } from "react";
import { Document } from "../../interfaces";
import {
  Flex,
  Box,
  Button,
  Text,
  H3,
  Span,
  Modal,
  Error,
} from "@fatlama/llama-library";
import dayjs from "dayjs";

import GetApi from "../../utils/api";
import { useSelector } from "../../state/utils";
import { getUser } from "../../state/selectors";
import { useHistory } from "react-router-dom";
import { ReqStatus } from "./Components";
import Dropdown from "../Common/Dropdown";
import { socialLinksRejectedReasons } from "./rejectionReasons";

export const getValidUrl = (url = "") => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, "");

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`;
  }

  if (newUrl[0] !== "h" && newUrl[0] !== "w") {
    return `https://${newUrl}`;
  }
  return newUrl;
};

const OnlinePresenceLink = (
  props: Document & { withoutAction?: boolean; noTitle?: boolean }
) => {
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState<string>();
  const [error, setError] = useState<string>();
  const [approveError, setApproveError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);

  const {
    status,
    url,
    reviewedBy,
    documentSubtype,
    noTitle,
    id,
    withoutAction,
  } = props;
  const history = useHistory();

  const api = GetApi();

  const approveLink = async () => {
    try {
      setIsLoadingApprove(true);
      setError(undefined);
      const res = await api.validateDocument({
        id,
      });

      if (res.success) {
        history.push(`${history.location.pathname}?refresh=true`);
      } else {
        setApproveError("Unable to approve document");
      }
    } catch (error) {
      setApproveError("Unable to approve document");
    } finally {
      setIsLoadingApprove(false);
    }
  };

  const rejectLink = async () => {
    if (!rejectionReason) {
      return;
    }
    try {
      setIsLoading(true);
      setError(undefined);

      const res = await api.rejectDocument({
        id,
        reason: rejectionReason,
      });

      if (res.success) {
        history.push(`${history.location.pathname}?refresh=true`);
        setShowRejectModal(false);
      } else {
        setError("Unable to reject document");
      }
    } catch (error) {
      setError("Unable to reject document");
    } finally {
      setIsLoading(false);
    }
  };

  const formattedLink = getValidUrl(url);
  return (
    <Flex flexDirection="column" mb={3}>
      <Flex
        flexDirection="column"
        border="thinLight"
        borderRadius={8}
        p={2}
        mb={2}
        flexWrap="wrap" // Added to wrap content when it overflows
      >
        {noTitle ? null : (
          <Text bold capitalize mb={0}>
            ONLINE_PRESENCE
          </Text>
        )}
        <Text small mb={0}>
          <Span small link style={{ wordBreak: "break-word" }}> {/* Added to break long URLs */}
            <a href={formattedLink} rel="noopener noreferrer" target="_blank">
              {url}
            </a>
          </Span>{" "}
        </Text>

        <ReqStatus status={status} />
        {reviewedBy && <Text mb={0}>By: {reviewedBy}</Text>}
        <Text small>
          Added: {dayjs(props.createdAt).utc().format("D MMM YY HH:mm")}
        </Text>
      </Flex>

      {withoutAction ? null : (
        <>
          <Flex justifyContent="flex-end" flexWrap="wrap"> {/* Ensure wrapping for buttons */}
            <Box width="auto" mr={2}>
              <Button
                variant="secondary"
                disabled={status === "REJECTED"}
                onClick={() => setShowRejectModal(true)}
                label="Reject"
              />
            </Box>
            <Button
              label="Approve"
              disabled={status === "VALIDATED"}
              isLoading={isLoadingApprove}
              onClick={approveLink}
            />
          </Flex>

          {approveError && <Error>{approveError}</Error>}
        </>
      )}
      <Modal isOpen={showRejectModal} onClose={() => setShowRejectModal(false)}>
        <Flex px={3} py={2} flexDirection="column">
          <H3 mb={2}>Reject social link</H3>
          <Box mb={2}>
            <Dropdown
              options={socialLinksRejectedReasons}
              handleChange={(event: any) => {
                setRejectionReason(event.target.value);
              }}
              value={rejectionReason}
            />

            {error && <Error>{error}</Error>}
          </Box>
          <Button
            isLoading={isLoading}
            alert
            variant="primary"
            disabled={!rejectionReason}
            onClick={rejectLink}
            label="Reject"
          />
        </Flex>
      </Modal>
    </Flex>
  );
};

export default OnlinePresenceLink;