export const BASE_ASSETS_URL = 'https://fat-lama-assets.s3-eu-west-1.amazonaws.com'
export const RMS_ASSETS_URL = `${BASE_ASSETS_URL}/rms`

export const FATLLAMA_ASSETS_URL = `${BASE_ASSETS_URL}/microsite`

// from market palce
export const MARKETPLACE_BASE_ASSETS_URL = 'https://assets.fatllama.com'
export const FAT_LLAMA_IMAGES = `${MARKETPLACE_BASE_ASSETS_URL}/images`
export const SMALL_IMAGE_URL = `${FAT_LLAMA_IMAGES}/small/`
export const SMEDIUM_IMAGE_URL = `${FAT_LLAMA_IMAGES}/smedium/`
export const MEDIUM_IMAGE_URL = `${FAT_LLAMA_IMAGES}/medium/`
export const LARGE_IMAGE_URL = `${FAT_LLAMA_IMAGES}/large/`
export const REBRAND_ASSETS_URL = `${MARKETPLACE_BASE_ASSETS_URL}/static/img/rebrand-assets/`
export const ICONS_URL = `${MARKETPLACE_BASE_ASSETS_URL}/static/img/icons/`
export const STATIC_IMG_ASSETS = `${MARKETPLACE_BASE_ASSETS_URL}/static/img/`
