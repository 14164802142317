import calculateTotalPayment from './calculateTotalPayment'
import applyPromocode from './applyPromocode'
import applyCredit from './applyCredit'
import roundPaymentValues from './roundPaymentValues'

const getPaymentData = (input: any) => {
  return roundPaymentValues(applyCredit(applyPromocode(calculateTotalPayment(input))))
}

export default getPaymentData
