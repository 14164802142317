// in the useDebounce file
import { useEffect } from 'react'
/**
 * @description for use in functions with side-effects but no return value
 * @export useDebouncedFunction
 */
const useDebouncedFunction = (handler, watchedValue, delay) => {
  useEffect(() => {
    const timeoutHandler = setTimeout(() => {
      handler()
    }, delay)
    return () => {
      clearTimeout(timeoutHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValue, delay])
}

export default useDebouncedFunction
