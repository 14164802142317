import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

import { Box, Flex, Span, Text, Button, getColor, getSpace } from '@fatlama/llama-library'

import { SMALL_IMAGE_URL } from '../../constants/imgUrls'
import ReadIcon from './ReadIcon'
import getPaymentDetailsFromOffer from '../../helpers/paymentHelpers/getPaymentDetailsFromOffer'
import { dayS, formatCurrency } from '../../helpers/formatCurrency'
import { OFFER_STATUS } from '../../interfaces/index'
import { Item } from '../../interfaces/index'
import { getItemUrl } from '../../helpers/getItemUrl'
// import Link from 'next/link'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  padding-top: ${getSpace(1)};
  padding-bottom: ${getSpace(1)};
  border: 1px solid ${getColor('whaleLight')};
`

const ItemImage = styled(Flex)`
  width: 164px;
  height: 120px;
  background-image: url("${(props) => SMALL_IMAGE_URL + props.src}");
  background-position: center;
    background-size: cover;
`

const Clamp = styled(Flex)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: ${getSpace(1)};
  margin-bottom: ${getSpace(1)};
  padding-bottom: 0;
  text-align: center;
`

const ItemContainers = styled(Flex)`
  ${(props: any) => {
    return props.only1Item ? 'justify-content: center' : 'overflow-x: scroll'
  }};
  padding: ${getSpace(1)};
  margin-bottom: ${getSpace(2)};
`

const ItemCard = styled(Flex)`
  background-color: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  overflow: hidden;
  min-width: 150px;
  flex-direction: column;
  margin-right: 4px;
  margin-left: 4px;
  align-items: center;
`

export const InChatItemCard = ({
  itemUrl,
  city,
  imgUrl,
  itemName
}: {
  imgUrl: string
  city?: string
  itemUrl?: string
  itemName: string
}) => {
  if (!itemUrl || !city) {
    return (
      <ItemCard>
        <ItemImage src={imgUrl} />
        <Clamp>
          <Text small>{itemName}</Text>
        </Clamp>
      </ItemCard>
    )
  }
  return (
    <a href={getItemUrl(city, itemUrl)}>
      <ItemCard cursor='pointer'>
        <ItemImage src={imgUrl} />
        <Clamp>
          <Text small>{itemName}</Text>
        </Clamp>
      </ItemCard>
    </a>
  )
}

const ActionButton = ({
  status,
  isMyMessage,
  setCurrentlyVisibleTransactionOffer
}: {
  status: OFFER_STATUS
  isMyMessage: boolean
  setCurrentlyVisibleTransactionOffer: any
}) => {
  // if (status === OFFER_STATUS.ACTIVE && isMyMessage) {
  //   return <Button fullWidth variant='warning' borderColor='red' label='Retract offer' />
  // }
  if (status === OFFER_STATUS.ACTIVE && !isMyMessage) {
    return (
      <Button
        onClick={setCurrentlyVisibleTransactionOffer}
        fullWidth
        secondary='whiteGreyOutline'
        label='View offer'
      />
    )
  }
  if (status === OFFER_STATUS.EXPIRED) {
    return <Button fullWidth variant='secondary' isDisabled label='Offer expired' />
  }
  if (status === OFFER_STATUS.CANCELLED) {
    return <Button fullWidth variant='secondary' isDisabled label='Offer retracted' />
  }
  return null
}

const OfferBubble = ({
  value,
  createdAt,
  isMyMessage,
  isRead,
  offer,
  setCurrentlyVisibleTransactionOffer
}: any) => {
  if (!offer) {
    return null
  }
  const numberItems = offer.items.length
  const currency = offer.items[0].currency
  const paymentDetails = getPaymentDetailsFromOffer(offer)
  return (
    <Flex minHeight='fit-content' flexDirection={isMyMessage ? 'row-reverse' : 'row'} mb={3}>
      <Container bg={isMyMessage ? 'whaleLight' : 'marineLight'} borderRadius={8}>
        <Text mb={1} large>
          Custom offer for {numberItems} item{numberItems > 1 ? 's' : ''}
        </Text>
        <ItemContainers only1Item={offer.items.length === 1}>
          {offer.items.map((item: Item) => (
            <InChatItemCard key={item.id} imgUrl={item.imgUrls[0]} itemName={item.itemName} />
          ))}
        </ItemContainers>
        <Text large>{formatCurrency(paymentDetails.offerPrice, currency)}</Text>
        <Text large bold>
          {paymentDetails.days} {dayS(paymentDetails.days)}
          <Span>
            {' '}
            ({dayjs(offer.fromDate).utc().format('D MMM')} -{' '}
            {dayjs(offer.toDate).utc().format('D MMM')})
          </Span>
        </Text>
        <Flex py={1} px={2}>
          <ActionButton
            setCurrentlyVisibleTransactionOffer={setCurrentlyVisibleTransactionOffer}
            status={offer.status}
            isMyMessage={isMyMessage}
          />
        </Flex>
        {offer.status === OFFER_STATUS.ACTIVE && (
          <Text fontSize={12} color='gsGraphite' small>
            Expires in {dayjs(offer.expiryDate).diff(dayjs(), 'hour')} hours
          </Text>
        )}
        <Box mr={2}>
          <ReadIcon createdAt={createdAt} isMyMessage={isMyMessage} isRead={isRead} />
        </Box>
      </Container>
    </Flex>
  )
}

export default OfferBubble
